<template>
  <div class="textUser">
    <div>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span style="font-family:文星标宋; font-size:22pt">东亚畜牧交易所</span
        ><span style="font-family:文星标宋; font-size:22pt">交易服务</span
        ><span style="font-family:文星标宋; font-size:22pt">协议</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:22pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">甲方：</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧现货产品交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">有限公司</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">电话：053</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">6-7399999</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">地址：</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >山东省潍坊市寒亭区海港路</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >45000号10号楼101</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">乙方：</span
        ><span style="font-family:仿宋; font-size:16pt; font-weight:bold">{{
          name
        }}</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">电话：</span
        ><span style="font-family:仿宋; font-size:16pt; font-weight:bold">{{
          tel
        }}</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="color:#0000ff; font-family:仿宋_GB2312; font-size:16pt"
          >用户编码：</span
        ><span style="font-family:仿宋; font-size:16pt; font-weight:bold">{{
          userCode
        }}</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >甲方系经合法批准、提供</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">大宗商品</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电子交易服务的服务商，乙方系有从事</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">畜牧产品</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >及相关产品交易经验</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">及资质</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">的法人、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >非法人组织、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >个体工商户。根据中华人民共和国有关的法律法规、《东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易规则（试行）》、《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">管理</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">办法</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >(试行)》、《东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >结算管理细则（试行）》、《东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >风险控制管理办法(试行)》以及在</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >官方网站及交易系统客户端上公布的相关管理办法、制度、规则(统称"业务规则”)的规定，甲方作为</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">(以下简称"</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">”)与乙方就</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >提供的交易系统平台业务事项达成共识，乙方确认已完全阅读甲方业务规则的相关内容并认可该内容，甲乙双方经过平等协商，自愿达成本协议。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">一</span
        ><span style="font-family:黑体; font-size:16pt">、</span
        ><span style="font-family:黑体; font-size:16pt">交易品种</span
        ><span style="font-family:黑体; font-size:16pt">及数量</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">按照</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">上市</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >说明书及相关交易规则规定</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">二、交易时间</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >1.交易时间：</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">每周一至周五</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >（国家法定节假日另行通知）</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >上午：9:30-11:30；下午：13:</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">0-15:</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">0；</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >当前交易日9:30至15:30为一完整交易日。</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易时间如果有变动以</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的公告为准。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">三、</span
        ><span style="font-family:黑体; font-size:16pt">交易方式</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方通过网络系统与甲方提供的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电子交易系统平台服务进行交易。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >甲方在计算机(数据存储器)中针对交易过程所形成的电子记录、数据电文等以电子形式存储的相关数据</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">和合同</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >具有法律效力。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">四、</span
        ><span style="font-family:黑体; font-size:16pt">履约订金</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方的订货履约订金</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">根据</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">电子</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">订单</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >标的物商品价值</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">确定</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >，临近交收月会阶段性调整履约订金，具体详见</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">相关规定。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">五、</span
        ><span style="font-family:黑体; font-size:16pt">费用</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方在参与交易交收过程中，需支付如下费用：交易手续费、交收手续费等相关费用。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易手续费：具体见相关交易品种上市说明书。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交收手续费：具体见相关交易品种上市说明书。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >有权根据产品市场行情对以上费用进行调整，调整通知通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >官网公布及通过交易系统客户端发送至乙方。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">六、</span
        ><span style="font-family:黑体; font-size:16pt">结算</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >具体结算规则及细则参照相关品种《东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >结算管理细则（试行）》及其修订版执行，修订的通知通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >官网公布及通过交易系统客户端发送至乙方。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">七、</span
        ><span style="font-family:黑体; font-size:16pt">风险管理</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >甲方以资金安全率来计算乙方的</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >持仓</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >风险</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方因为可用资金不足、遇到紧急情况或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >认为必要的情况下，将根据</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易规则</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >及风险控制管理办法</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">对乙方的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">持仓</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >进行代为转让，转让价格以</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">即时成交价</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >为准，乙方对代为转让产生的结果认可，不持任何异议。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于国家法律、法规、政策的变化、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易规则的修改、紧急措施的出台等原因，甲方有权根据</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >要求对乙方的未转让</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">持仓</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >进行全部代为转让，转让价格以</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">即时成交价</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >为准，乙方对因此产生的结果认可，不持任何异议。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">八、</span
        ><span style="font-family:黑体; font-size:16pt">实物交收</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >具体交收规则和细则参照相关品种《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交收细则（试行）</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >》及其修订版执行，修订的通知通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >官网公布及通过交易系统客户端发送至乙方。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">九、</span
        ><span style="font-family:黑体; font-size:16pt">入市及交易</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方在甲方开立交易账户，此账户由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >统一进行监管，并且必须经过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的审核后方可进行交易。交易账户实行一户一码制。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方入市须在</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >会员服务机构</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的配合下，填写并上传以下文件：</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >法人、</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >非法人组织、</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >个体工商户</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >通过</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >线上开户</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >申请成为</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >交易用户</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >须提交以下资料</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; font-weight:normal"
          >：</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >通过在线评估问卷，阅读并认可</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >协议及《风险告知书》等全部内容后，填写相关信息，上传开户资料(</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">有效证照</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电子扫描件及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">加盖公章的</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >复印件、企业</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >法定代表人等负责人</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >身份证电子扫描件及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">加盖公章的</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >复印件或被授权人的身份证复印件及扫描件</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">并</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >加盖公章、授权书</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">并</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >加盖公章)，输入手机验证码，阅读并认可《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户管理办法（试行）</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >》，提交注册信息</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >并关联本组织的银行账户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，经</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >审核通过后，可成为</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >符合入市条件的以自然人形式</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">参与交易</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">线上开户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">申请</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >须提交以下资料</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >通过在线评估问卷，阅读并认可</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >协议及《风险告知书》等全部内容后，填写个人相关信息，上传</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >产业证明文件、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >开户资料(身份证正反面照片或扫描件、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本人</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >银行卡正面照片或扫描件)，输入手机验证码，阅读并认可《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户管理办法（试行）</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >》，提交注册信息</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >并关联本人的银行账户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，经</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >审核通过后，可成为</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >资料上传及邮寄：</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >入市所有文件的电子版由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本人上传至</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在线开户端。纸质版资料须邮寄至</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">(</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >山东省潍坊市寒亭区海港路</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >45000号10号楼201客服部</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">)。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >必须保证其提交入市资料的真实性、完整性、准确性、有效性，同时需要在山东交易市场清算</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">所有限公司</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >所指定的银行开立结算账户，用以实现与</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >履约订金专用账户之间的资金划转。如乙方提供的入市资料有虚假，乙方须承担因此而引起的一切责任和后果。当有关资料发生变化时，乙方及时以有效方式通知甲方。如因资料发生变化，乙方应在发生变更后及时书面通知甲方，否则乙方承担因未履行通知义务而产生一切后果的法律责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >所有的协议和通讯方式都须以签</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">名或电</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >子记录进行确认。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方必须对交易账户和所有密码进行妥善的保管，并独自承担因保管不善而造成的一切后果。如若密码丢失，乙方须提供其本人有效的身份证件(个人</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">)或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">有效证照</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">（副本）</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电子扫描件(法人</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)以及其它甲方要求的信息，经</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >核对信息无误后，方可将密码重置至初始状态。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">5</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方必须确保其本人(或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)有满足维系交易系统顺利运行配置要求的软件和硬件系统。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">6</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方的交易账户只限本人(或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)使用，不得转借他人(或其它法人)，如转借他人所引起的一切纠纷和损失均由乙方自行解决和承担。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">7</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">甲方仅通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >账号和密码核实乙方身份，乙方应妥善保存好其本人(或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)的交易账号和相关的密码。通过交易账号及密码登陆进行的交易操作，无论是否本人(或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)交易，均视为是乙方本人(或</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >)操作。所有因密码遗失及被盗导致的利益上的损失，均与</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >无关，由乙方自行承担。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">8</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >所有乙方通过网络发出的交易指令，一经发出</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">并成交</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >后，均视为本人发出，不得撤销或撤回。所有乙方通过网上交易系统发出的交易指令，以</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电脑记录数据为准。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">9</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方对当日的交易结算结果有异议时，应在</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >下一个交易日开市前三十分钟内以书面</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">形式向</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >提出。乙方在上述规定时间内没有对结算数据提出异议，甲方则视作乙方已认可结算数据的准确性。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">10</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方必须遵守和接受</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >规定的有关产品交易事项的一切规则，包括</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >现行及后期的修改版本，或者</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >新制定的相关制度、规则和办法等，以上修改的版本或新制定的相关制度、规则和办法，均通过</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >官网公布及通过交易系统客户端发送至乙方。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">11</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方在提取账户资金时，以其交易账户内可出资金为准。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方交易账户激活后连续十二个月未交易的，为保障乙方账户安全，乙方同意甲方视具体情况对乙方交易账户予以冻结。交易账户冻结后乙方要求继续交易的，可向</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >申请解除冻结。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方因未遵守甲方相关制度且需销户的情况下，甲方有权对乙方交易账户先行予以冻结。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">十</span
        ><span style="font-family:黑体; font-size:16pt">.</span
        ><span style="font-family:黑体; font-size:16pt">违约条款</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >任何一方违反本协议给对方造成损失，违约方需承担相应经济赔偿责任;双方违约的，各自对其违约行为造成的损失承担赔偿责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">十</span
        ><span style="font-family:黑体; font-size:16pt">一.</span
        ><span style="font-family:黑体; font-size:16pt">免责条款</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >甲方及其工作人员对市场行情的判断和操作建议仅供参考，甲方及其工作人员向乙方作出获利或不遭受损失的担保或承诺均不具有法律约束力，乙方据此进行交易产生的亏损由乙方自行承担。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电讯信号的中断、互联网的不畅通及乙方自身网络设备及电讯设备不稳定等原因而使乙方遭受的损失，由乙方自行承担，甲方不负有任何责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">由于</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">疫情、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >地震、水灾、火灾、暴动、罢工、战争、政府管制、国际或国内的禁止或限制以及停电、技术故障、电子故障等不可抗力因素导致的交易中断、延误等风险，甲方不承担责任，但应在条件允许的范围内采取必要的补救措施以减少乙方因不可抗力造成的损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于发生不可抗力及国家有关法律、法规、规章、政策的改变、紧急措施的出台等导致乙方风险，甲方不承担责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">5</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易期间，如果因乙方自身原因导致其资产被司法机关采取财产保全或强制执行措施，或者出现破产、解散等情况时，乙方将面临无法正常交易的风险，由此而造成的交易风险由乙方自行承担，甲方不承担责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">十</span
        ><span style="font-family:黑体; font-size:16pt">二</span
        ><span style="font-family:黑体; font-size:16pt">．</span
        ><span style="font-family:黑体; font-size:16pt">协议的生效与终止</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">乙方</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在注册过程中，</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >通过网络页面勾选、点击确认、进行下一步操作即表示签署并</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >完全接受本协议全部条款</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >本协议生效。甲方</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >有权在必要时变更</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">协议</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">内容</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">协议变更后</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">继续</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">使用</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >甲方交易系统</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">则</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >视为接受变更后的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">协议。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >本协议的所有条款，对于甲、乙双方均具有法律效力，任何一方均不得违反。本协议的修订或更改须以书面方式进行，并由双方签署确认。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >甲方对本协议各项条款拥有最终解释权。如甲乙双方就本协议发生争议，以友好协商的原则商议解决。调解不成的，必须至</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">潍坊</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >仲裁委员会并按其仲裁规则进行仲裁。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >任意一方不得以山东交易市场清算所、关联银行、乙方所在地等主体改变管辖机构和管辖地。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >乙方可以通过解除签约的方式终止与甲方签订的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本协议</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >。双方终止协议履行，乙方必须结清与甲方的所有费用和欠款，且在终止协议当天无任何成交记录。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">5</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >除非本协议另有约定，本协议有效期至甲方终止</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >资格之日止。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">6</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >本协议书为依据现行有效的法律、法规、政策、规章及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >各项规则所订。如因有关法律、法规、政策、规章、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >规则等发生变动，导致本协议书相关条款与其规定相抵触的，应以新的规定为准;需要依据该等变动对本协议书相关条款进行调整的，甲、乙双方应同意签订补充协议。甲方有义务将其业务规则明示于甲方</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">官方</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">网站www.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">ealce</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.com及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">甲方</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易系统客户端，乙方有义务获知并充分理解业务规则。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">7</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >《关于禁止代客交易及交易账号安全保障的说明》</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">《交易风险</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">告知书</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >》等相关文件将作为本协议的附件，本协议的附件为本协议不可分割的部分，与本协议书具有同等的法律效力。乙方承诺已充分阅读本协议及附件内容，包括其中有关甲方的免责条款，乙方愿意并有能力承担一切相应风险。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >(以下无正文)</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >附件一：《关于禁止代客交易及交易账号安全保障的说明》</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >附件二：《交易风险</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">告知书</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        class="imgp"
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">甲方：</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧现货产品交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">有限公司</span>
        <img class="img" src="../../assets/images/14061644330896.jpg" style="width: 150px" />
      </p>
      <p style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt">
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="font-size:16pt; line-height:300%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">乙方：</span
        >
        <!-- <img :src="signatureImg" style="width: 70px" /> -->
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">签署日期</span
        ><span style="font-family:'Times New Roman'; font-size:16pt">:</span
        ><span style="font-family:仿宋; font-size:16pt; font-weight:bold">{{
         Number(new Date()) || DateFormateY
        }}</span>
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <br style="page-break-before:always; clear:both" />
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >附件一</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:文星标宋; font-size:22pt"
          >关于禁止代客交易及交易账号安全保障的说明</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">尊敬的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >禁止代客交易以及变相代客交易，严厉禁止具体细化至某点位交易合同的订立、转让，请广大</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >提高防范意识，切勿将交易账户交由他人操作，对于由于代客交易行为产生的交易风险及损失，</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >不承担任何责任。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">本人/</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >承诺首次登陆更改密码，不会将交易账号及密码泄露给他人，且愿意承担因未遵守上述规定而造成的全部损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">本人/</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >特此声明并同意将此声明作为与</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">签订</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易服务协议</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的有效补充。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span
style="font-family:'Times New Roman'; font-size:16pt"
          >&nbsp;</span
        >
      </p>
      <p
        style="font-size:16pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >附件二</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"
      >
        <span style="font-family:文星标宋; font-size:22pt">交易风险</span
        ><span style="font-family:文星标宋; font-size:22pt">告知书</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">尊敬的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">东亚畜牧</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">现货产品</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">有限公司</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">(以下简称"</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >”)提供的电子交易系统平台业务存在一定的风险性，在使用</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >电子交易系统平台进行交易过程中所产生的对</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的风险承受能力、理解风险的程度、风险控制能力以及现货交易经验有一定的要求</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">通过</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >网络页面勾选、点击确认，进行下一步操作即表示已达成协议并同意接受《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易服务协议</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >》的全部约定内容，已充分认知并了解本告知书载明的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">风险</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >本告知书旨在向</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >充分揭示交易过程中可能存在的风险，并且帮助</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >评估和确定自身的风险承受能力。本告知书披露了交易过程中可能发生的各种风险因素，但是并没有完全包括所有关于</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易品种的风险。鉴于交易过程中风险的存在，在签署</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易服务协议</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >及进行交易前，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >应该仔细研读本风险告知书，必须确保自己理解有关交易的性质、规则，并依据自身的经验、目标、财务状况、承担风险的能力等自行决定是否参与此交易。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >对于本告知书有不理解或不清晰的地方，应该及时咨询相关方</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，并确保对</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >告知书不存在</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">任何</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">异议。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">一、</span
        ><span style="font-family:黑体; font-size:16pt">温馨提示</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于电子交易具有一定的风险性，故不适合使用养老基金、债务资金(如银行贷款、民间贷款)等进行交易的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">在签署</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易服务协议</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >前请仔细阅读并承诺遵守《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户管理办法（试行）</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >等文件的相关</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">要求。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >提供的电子交易系统平台业务只适合于满足以下条件的</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">：</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">(一)经</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >行政审批服务局核准登记</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >从事交易相关商品的生产、流通、消费等经营活动的法人、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >非法人组织、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">个体工商户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >，或具有行业背景的自然人</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >(二)承诺遵守《东亚畜牧交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">预售</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易规则（试行）》、《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所交易用户管理办法（试行）</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">《</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >东亚畜牧交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >预售交易业务</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >风险控制管理办法（试行）</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">》</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >等相关配套制度及本告知书;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >(三)具有一定资金实力及良好的信誉;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">(四)</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >法人单位、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >非法人组织、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >个体工商户类</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >：具有健全的组织架构、财务管理制度及完善的业务管理制度;</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >符合入市条件的以自然人形式</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >参与交易</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >的</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >：年龄在18至6</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">5周</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >岁之间，并且具有</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">畜牧</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">行业</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">养殖</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >、加工、销售或其它相关的背景;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >(五)经营范围符合国家法律、行政法规和有关政策的规定;</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">(六)</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >规定的其它条件。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">二、</span
        ><span style="font-family:黑体; font-size:16pt">相关的风险揭示</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(一)政策风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于国家法律、法规、政策的变化，紧急措施的出台，相关监管部门监管措施的实施，以及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易规则的修改等原因，均可能会对</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的现货交易产生影响，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >须承担由此导致的损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(二)价格波动的风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在电子交易系统进行交易时，假如参与交易的商品价格走势出现波动导致货物升贬值，因而存在出现交易亏损的可能性，如果不能有效控制风险，则可能遭受较大的损失，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >必须独自承担由此导致的一切损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(三)</span
        ><span style="font-family:楷体; font-size:16pt">资质</span
        ><span style="font-family:楷体; font-size:16pt">风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易所将严格审核会员服务机构相关信息，对符合交易所要求的方可获得相关资质，可能存在伪造、</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">冒用</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >资质文件或冒充交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">工作人员</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">从而</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >通过诱导开户、代为开户、做出获利承诺、通过直播间或投资群频繁喊单等违规方式，鼓动</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >开户并参与交易的情况。上述行为严重破坏交易市场正常秩序</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">，</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >请交易用户仔细核对相关资质文件，</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >如果不能有效</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">辨别真伪</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >，则可能遭受较大的损失，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >必须独自承担由此导致的一切损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(四)交易风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >需要了解：在交易中如果市场走势对您不利，导致您的履约</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">订金</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >或可用资金不足，</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >交易所</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >会按照约定的时间和方式通知您追加</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >履约订金</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >或可用资金</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">发出追加</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">履约订金</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">通知后，</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >交易用户</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >必须在下一个交易日开市前三十分钟内补足至</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >履约订金</span
        ><span
          style="font-family:仿宋_GB2312; font-size:16pt; text-decoration:underline"
          >最低余额为正</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >必须有条件满足随时追加</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">履约订金</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的要求，否则其存货将会被代为转让，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >必须承担由此造成的全部损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >及其工作人员不会对</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >作出获利保证，并且不会与</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >分享收益或共担风险。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的成交单据必须是建立在自己的自主决定之上。</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >及其工作人员提供的任何关于市场的分析和信息，仅供</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >参考，同时也不构成任何要约。由此而造成的交易风险由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在电子交易的过程中，有可能出现非人为的明显的错误报价，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >可能事后会对错价及错价产生的盈亏作出纠正，由此而造成的交易风险由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">5.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易期间，如果因</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >自身原因导致其资产被司法机关采取财产保全或强制执行措施，或者出现破产、解散等情况时，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >将面临无法正常交易的风险，由此而造成的交易风险由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(五)网上交易风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >1.此业务通过电子通讯技术以及互联网技术来实现。有关通讯服务及软、硬件服务由不同的供应商提供，可能会存在品质和稳定性方面的风险;交易所及其会员服务机构不能控制电讯信号的强弱，也不能保证交易用户客户端的设备配置及其连接的稳定性以及互联网传播和接收的实时性。互联网</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >上的数据传输可能会因通信繁忙出现延迟,或因其它不可抗拒原因出现中断、停顿或数据不完全、数据错误等情况,由此可能导致您的网上交易出现延迟、停</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">顿</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >或中断。造成网上交易的用户无法及时进入交易系统,无法查看行情而影响交易造成的损失由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt"
          >2.交易用户的电脑系统有可能被病毒以及网络黑客攻击，从而使交易用户的决策指令无法正确及时执行。对于上述不确定因素的出现也存在着一定的风险，有可能会对交易用户的交易产生影响，交易用户应该充分了解并承担由此造成的全部损失</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于不慎将账号、交易密码遗失,账户被他人盗用,所发生的一切损失由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >由于网络传输速度的原因,互联网发布的行情信息及其它信息可能会滞后、出现错误并误导</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >,由此可能导致的损失由</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">自行承担。</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:楷体; font-size:16pt">(六)不可抗力风险</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">由于</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">疫情、</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >地震、水灾、火灾、暴动、罢工、战争、政府管制、国际或国内的禁止或限制以及停电、技术故障、电子故障等其他无法预测和防范的不可抗力事件，都有可能会对</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的交易产生影响，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >应该充分了解并自行承担由此造成的全部损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:黑体; font-size:16pt">三</span
        ><span style="font-family:黑体; font-size:16pt">、</span
        ><span style="font-family:黑体; font-size:16pt">特别提示</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">1.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在参与交易之前务必详尽的了解交易品种的基本知识和相关风险以及</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >有关的业务规则等，依法合规地从事商品交易业务。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">2.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >与现有的国内其他</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >相比，在交易模式、交易规则等方面存在着一定的差别;而且</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >为了确保交易</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">“</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >公开、公平、公正”和健康稳定地发展，将采取更加严格的措施，强化交易过程中的监管。请您务必密切的关注</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >交易行情的公告、风险提醒等信息，及时了解交易风险状况，做到理性交易，切忌盲目跟风。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">3.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >在开通交易之前，请配合</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">了解</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >的适当性管理规定，完整、如实地提供入市所需要的信息，不得采取弄虚作假等手段规避有关的要求，否则，</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >有权拒绝和停止对其提供交易服务。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">4.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">入市需通过</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >适当性评估。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">5.</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >诚挚的希望和建议</span
        ><span style="font-family:仿宋_GB2312; font-size:16pt">交易用户</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >，从风险承受能力等自身实际情况出发，审慎地决定是否参与交易。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">6.</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt"
          >本风险告知书的风险揭示事项仅为列举性质，未能详尽列明商品交易的所有风险和可能影响商品合同价格的所有因素。您在参与商品交易前，应认真阅读相关业务规则及相关文件，对商品交易业务所特有的规则必须了解和掌握，全面理解交易规则及实施细则，对自身的经济承受能力、风险控制能力、身体及心理承受能力、理解能力做出客观判断，对交易系统作仔细的、全面的研究，并确信自己已做好足够的风险评估与财务安排，避免因参与商品交易而遭受难以承受的损失。</span
        >
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span style="font-family:仿宋_GB2312; font-size:16pt">&nbsp;</span>
      </p>
      <p
        style="line-height:28pt; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"
      >
        <span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >本人</span
        ><span
          style="font-family:'Times New Roman'; font-size:16pt; font-weight:bold"
          >/</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >特此声明并同意将此声明作为与</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >交易所</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >签订</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >交易服务协议</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >的有效补充。本人</span
        ><span
          style="font-family:'Times New Roman'; font-size:16pt; font-weight:bold"
          >/</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >本组织</span
        ><span
style="font-family:仿宋_GB2312; font-size:16pt; font-weight:bold"
          >已阅读理解和同意以上全部内容，自愿承担上述风险以及由此产生的一切损失。</span
        >
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import md5 from 'js-md5'
export default {
  name: "UserProtocol",
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  props: {
    signatureImg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      name: localStorage.getItem("name_w")
        ? localStorage.getItem("name_w")
        : "---",
      tel: sessionStorage.getItem("sessionTel")
        ? sessionStorage.getItem("sessionTel")
        : "---",
      userCode: this.sessionInfo.userCode
    };
  },
  filters: {
    tels(val) {
      if (val != "---") {
        val = "" + val;
        var tel1 = val.substr(0, 3) + "****" + val.substr(7);
        return tel1;
      } else {
        return val;
      }
    }
  },

  mounted() {},

  methods: {}
};
</script>

<style lang="scss" scoped>
.textUser span {
  color: #333333 !important;
}
.textUser {
  width: 100%;
  height: 100%;
  // padding-top: 75px;
  // position: relative;
}
.top {
  padding: 30px 0;
  background-color: #fff;
  margin-bottom: 30px;
}
.content {
  margin-top: 59px;
  // height: 40vw;
  // width: 100%;
  // overflow: auto;
  // overflow-y: auto;
}
.imgp{
  position: relative;
  .img{
    position: absolute;
    top: -40px;
    left: 140px;
  }
}
</style>
