<template>
  <!-- <div class="container" :class="{enFooter: isEnFooter}"> -->

  <div class="container">
    <el-row type="flex" justify="center" class="rowContent">
      <el-col :span="6" class="topPart">
        <div class="logo">
          <img
            v-if="isHaiBei"
            src="../../../assets/images/aside/logo.png"
            :alt="$t('message.Logo_image')"
          />
          <img
            v-else
            src="../../../assets/images/aside/logo.png"
            :alt="$t('message.Logo_image')"
          />
          <!-- <div class="texts" @click="goGuide">新手引导</div> -->
        </div>
        <!-- <div class="tlt" style="font-size:12px;height: 30px;line-height: 30px;">© 2017-2020</div>
        <div class="tlt">{{ logoName }}</div> -->
      </el-col>
      <el-col :span="cssFooterSpan" class="leftPart">
        <!-- <div>  `
          <dl>
            <dd
              style="margin-top: 0px;"
            >客服邮箱</dd>
            <dd>...</dd>
          </dl>
        </div> -->

        <!-- <div>
          <dl>
            <dt>{{ $t('message.Clauses') }}</dt>
            <dd>
              <router-link to="/privacypolicy" target="_blank">{{ $t('message.Privacy_Policy') }}</router-link>
            </dd>
            <dd>
              <router-link to="/legalstatement" target="_blank">{{ $t('message.Legal_statement') }}</router-link>
            </dd>
            <dd>
              <router-link to="/userprotocol" target="_blank">{{ $t('message.Terms_and_Conditions') }}</router-link>
            </dd>
          </dl>
        </div> -->
        <div v-for="(noriceCell, index) in list" :key="index">
          <dl>
            <dt style="color: #fff">{{ noriceCell.name }}</dt>
            <dd
              v-for="(footerCell, index1) in noriceCell.contents"
              :key="'footercell' + index1"
            >
              <a
                style="color: #fff;cursor:pointer;"
                class="conItems"
                target="_blank"
                @click="gotoMsg(footerCell)"
                >{{ footerCell.title }}</a
              >
            </dd>
          </dl>
        </div>
        <div>
          <dl
            style="    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;"
          >
<!--           <dt>{{ $t('message.Tools') }}</dt>-->
<!--            <el-popover-->
<!--              placement="top-start"-->
<!--              :title="$t('message.Scan_download')"-->
<!--              width="300"-->
<!--              text-align="center"-->
<!--              trigger="hover"-->
<!--              popper-class="down_app"-->
<!--              :visible-arrow="false"-->
<!--            >-->
            <img
              src="../../../assets/images/homePage/iosCode.png"
              width="100px"
              heigh="100px"
            />
            <span style="color: #fff">Android下载</span>
<!--             <dd slot="reference"><p class="footerButton" target="_blank" @click="dowmloadApp">{{ $t('message.Download_the_APP') }}</p></dd>-->
<!--             </el-popover>-->
<!--            <dd>-->
<!--               <router-link to="/CoinMessages" target="_blank">{{ $t('message.Introduction_of_Digital_Assets') }}</router-link>-->
            </dd>
          </dl>
        </div>
        <!-- <div>
          <dl>
            <dt>{{ $t('message.About') }}</dt>
            <dd>
              <router-link
                to="/bottomLink/tab/abount/AboutGude"
                target="_blank"
              >{{ $t('message.About_us') }}</router-link>
            </dd>
            <dd>
              <p class="notive" @click="goNotices">{{ $t('message.Media') }}</p>
            </dd>
            <dd>
              <router-link to="/vip" target="_blank">{{ $t('message.Institutional_Services') }}</router-link>
            </dd>
            <dd>
              <router-link to="/earenquiry" target="_blank">{{ $t('message.Enforcement_and_Regulatory_Enquiry') }}</router-link>
            </dd>
          </dl>
        </div> -->
      </el-col>
    </el-row>
    <!-- <div class="foot-text" style="color: rgba(219,222,246,0.5);">{{ $t('message.Bt_fengxian') }}</div> -->
  </div>
</template>
<script>
/*global protocol http*/
export default {
  name: "Footer",
  data() {
    return {
      cssFooterSpan: 18,
      isEnFooter: false,
      seen: false,
      logoName: window.g.APP_TITLE_ALL,
      list: [],
      isHaiBei: window.g.APP_IS_HAIBEI
    };
  },
  mounted() {
    this.setLanguageCss();
    this.getFooterList();
  },
  methods: {
    goGuide() {
      this.$router.push("/guide");
    },
    dowmloadApp() {
      window.location.href = window.g.APP_DOWNLOAD_PATH
        ? window.g.APP_DOWNLOAD_PATH
        : "";
    },
    // 根据语言改变样式
    setLanguageCss() {
      // 防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          localStorage.setItem("cssFooterSpan", 16);
          localStorage.setItem("isEnFooter", true);
          this.cssFooterSpan = parseInt(localStorage.cssFooterSpan);
          this.isEnFooter = localStorage.isEnFooter;
          break;
        default:
          break;
      }
    },
    goNotices() {
      window.sessionStorage.setItem("selectnPage", 1);
      this.$router.push("/notice");
    },
    goNoticess() {
      window.sessionStorage.setItem("selectnPage", 1);
      this.$router.push("/notice");
    },
    getFooterList() {
      this.list = [];
      protocol.param_lowerColumnAndTheirContent.param.columnId =
        window.c.aboutUs; // 2020.5.6 新增 8/18修改
      protocol.param_lowerColumnAndTheirContent.param.page = 0;
      protocol.param_lowerColumnAndTheirContent.param.size = 100;
      http.getRes(protocol.param_lowerColumnAndTheirContent).then(response => {
        const { content } = response.data.value;
        if (content) {
          this.list = content;
        }
      });
    },
    // 跳转详情
    gotoMsg({ id, title }) {
      console.log(title);
      this.$router.push({
        name: "footerDetails",
        params: {
          id
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
* {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

$colorTlt: #b6c0cc;
$colorContent: #889099;
.conItems:hover {
  color: #889099 !important;
}
.container {
  min-height: 180px;
  padding: 10px 0;
  background-color: $themeBaseColor;
  position: relative;
  // background-image: linear-gradient(0deg,#283f70 0,#264278 100%);
}
.rowContent {
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.logo {
  // padding-top: 10px;
  padding-right: 30px;
  font-size: 30px;
  font-family: 宋体;
  display: flex;
  justify-content: space-between;
  .texts {
    color: #fff;
    padding-top: 10px;
    cursor: pointer;
  }
  img {
    vertical-align: middle;
    height: 50px;
  }
  span {
    vertical-align: middle;
    color: #b6c0cc;
  }
}
.tlt {
  font-size: 15px;
  line-height: 50px;
  color: #b6c0cc;
}

.linkImg {
  display: flex;
  display: -webkit-inline-flex;
  justify-content: space-between;
  width: 150px;
}
.linkImg img {
  height: 30px;
}

.rightPart div {
  float: right;
  max-width: 20%;
  dl {
    margin-left: 20px;
    dt {
      color: $colorTlt;
    }
    dd {
      margin-top: 10px;
      a {
        color: $colorContent;
      }
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}

.enFooter .rightPart div {
  max-width: 40%;
}
.leftPart {
  height: 179px;
  padding-top: 10px;
}
.leftPart div {
  float: left;
  max-width: 25%;
  padding-right: 30px;
  box-sizing: border-box;
  dl {
    margin-left: 20px;
    dt {
      // color:$colorTlt;
      width: 100px;
      font-size: 14px;
    }
    dd {
      font-size: 14px;
      margin-top: 10px;
      a:hover {
        color: $colorContent;
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
.leftPart .download {
  float: right;
  margin-left: 10px;
  dt {
    margin: 10px auto;
    color: $colorTlt;
    text-align: center;
  }
}

.enFooter .leftPart div {
  max-width: 40%;
}

.topPart {
  height: 179px;
}
.foot-text {
  text-align: center;
  width: 100%;
  max-width: 1200px;
  font-size: 12px;
  margin: 0 auto;
  height: 40px;
  line-height: 35px;
  border-top: 1px solid #36538b;
  border-bottom: 1px solid #36538b;
}
.el-col-6 {
  width: 35%;
}
.footerButton {
  cursor: pointer;
}
</style>
<style lang="scss">
.down_app {
  background: #263562;
  text-align: center;
  border: #263562;
}
.el-popover__title {
  color: #fff;
}
</style>
