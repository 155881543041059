<template>
  <div class="noticess">
    <div class="content">
      开通市场：
      <el-select v-model="selectMarket" placeholder="请选择要开通的市场">
        <el-option
          v-for="item in marketOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.isDisabled"
        >
        </el-option>
      </el-select>
      <div v-if="selectMarket == 4" class="openContent">
        <div style="line-height:50px;">
          您好，您即将进行预售交易模式认证，请谨慎操作！
        </div>
        <div>
          <div class="d2_title">
            邀请码:
            <el-input
              style="width:500px;"
              v-model="inviteInput"
              placeholder="请输入邀请码"
            ></el-input>
          </div>
        </div>
        <div class="d2_btns">
          <el-button
            @click="inviteEnter"
            type="primary"
            style="margin-left: 15px"
            :disabled="isDisabled1"
            >下一步</el-button
          >
        </div>
      </div>
      <div v-else class="openContent">
        <div style="line-height:50px;">
          您好，您即将进行{{ lstName }}模式，请确认！
        </div>
        <div class="d2_btns">
          <el-button
            @click="lstEnter"
            type="primary"
            style="margin-left: 15px"
            :disabled="isDisabled"
            >下一步</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import noticeItem from "./noticeItem";
export default {
  components: {
    // noticeItem
  },
  data() {
    return {
      selectMarket: 4,
      marketOptions: [
        {
          label: "预售交易",
          value: 4,
          isDisabled: false
        },
        {
          label: "现货挂牌",
          value: 1,
          isDisabled: !window.g.LST_FLAG
        },
        {
          label: "现货竞价",
          value: 2,
          isDisabled: !window.g.LST_FLAG
        }
        // {
        //   label: "产能预售",
        //   value: 3,
        //   isDisabled: true
        // }
      ],
      isDisabled: false,
      lstName: "现货挂牌",
      isDisabled1: false,
      inviteInput: ""
    };
  },
  watch: {
    selectMarket(val) {
      if (val == 1) {
        this.lstName = "现货挂牌";
      } else if (val == 2) {
        this.lstName = "现货竞价";
      } else if (val == 3) {
        this.lstName = "产能预售";
      } else {
        this.lstName = "现货挂牌";
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 市场绑定(购销)
    inviteEnter() {
      sessionStorage.setItem("marketId_w", 28);
      var that = this;
      this.isDisabled1 = true;
      if (this.inviteInput == "") {
        this.$EL_MESSAGE("请填写邀请码");
        this.isDisabled1 = false;
        return;
      }
      protocol.param_dredgeBazaars.param.lnvitationCode = this.inviteInput.replace(
        /\s*/g,
        ""
      );
      protocol.param_dredgeBazaars.param.lnvitationCodeMustFillIn = true;
      http.postFrontMarket(protocol.param_dredgeBazaars).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled1 = false;
          }, 1500);
          // 成功,检测是否通过协议
          //   this.getAgreementEnter();

          // this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.inviteInput = "";
          localStorage.setItem("atPresentFirmId", value);
          this.$router.go(0);
        } else {
          that.isDisabled1 = false;
          //   this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 市场绑定(挂牌/竞价)
    lstEnter() {
      sessionStorage.setItem("marketId_w", 18);
      var that = this;
      this.isDisabled = true;
      http.postFrontMarket(protocol.param_dredgeBazaar).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled = false;
          }, 1500);
          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          localStorage.setItem("atPresentFirmId", value);
        } else {
          that.isDisabled = false;
          // this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.$store.state.common.sessionInfo.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          this.$store.commit("setFirmList", value);
          localStorage.setItem("setFirmList_w", JSON.stringify(value));

          if (value.length != 0) {
            if (value[0].marketId == 18) {
              //   this.bidFlagss = 1;
              this.$store.commit("setIsFlagss", 1);
              localStorage.setItem("bidFlagss", 1);
            } else if (value[0].marketId == 28) {
              //   this.bidFlagss = 2;
              this.$store.commit("setIsFlagss", 2);
              localStorage.setItem("bidFlagss", 2);
            }
          } else {
            this.$store.commit("setIsFlagss", 0);
            this.$store.commit("setIsFlagss", 0);
          }
        }
      });
      this.$emit("reloads");
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
/deep/ .wscnph {
  width: 100%;
}
.noticess {
  // border: 1px solid #e6ecf2;
  width: 100%;
  margin: 0 auto;
  // padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  // height: 100%;
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
}
.newsContent {
  overflow: hidden;
}
.newsDetails .newsTitle {
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
}
.newsDetails .newsImage {
  width: 600px;
  margin: 10px auto;
}
.newsDetails .newsImage img {
  width: 100%;
}
.titles {
  padding: 15px;
  font-size: 16px;
  background-color: #f2f6fa;
}
.openContent {
  margin-top: 20px;
  .d2_title {
    line-height: 50px;
  }
  .d2_btns {
    text-align: center;
    margin-top: 30px;
  }
}
.content {
  padding: 30px;
  .guideItem {
    div {
      font-size: 16px;
    }
    .title {
      font-weight: 700;
      margin: 30px 0;
      font-size: 20px;
    }
    .nextBox {
      display: flex;
      align-items: center;
    }
    .btns {
      margin-top: 10px;
    }
  }
}
.nextImg {
  width: 30px;
  height: 30px;
  margin: 0 30px;
}
</style>
