<template>
  <div id="eletronSignPage" class="eletronSignPage">
    <!-- <div class="titleEle">
      <span>电子签名</span>
      <span class="backIcon" @click="goPath"> <van-icon name="cross" /> </span>
    </div> -->
    <!-- <HeadBack title="电子签名" router-path="/sureData" /> -->
    <div class="eletronSignPageBox">
      <div class="headWrap">
        请签名
      </div>
      <!-- :style="`height:${esignHeight}px`" -->
      <div class="backF">
        <vue-esign
          v-if="reload"
          ref="esign"
          :is-crop="isCrop"
          :line-width="lineWidth"
          :line-color="lineColor"
          :bg-color.sync="bgColor"
          :height="esignHeight"
        />
        <!-- <img id="bottomImg" src="../../assets/images/schedule/signature.png" alt="" class="bottomImg"> -->
        <img v-if="resultImg" :src="resultImg" alt="" class="resultStyle">
      </div>
      <div class="bottomBtn">
        <div class="cancleBtn" @click="handleReset()" >重签</div>
        <div class="nextStepBtn" :disabled="disable" @click="handleGenerate()">
          <!-- 灰色 -->
          <div v-if="disable" class="backImg" style="color: lightgray">保存</div>
          <img v-else  class="backImg"  style="color: #000">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*global  http protocolUOA*/
// import HeadBack from '@/components/base/head/HeadBack'
// import schedule from '@/components/base/schedule/schedule'
import { mapGetters } from 'vuex'
export default {
  components: {
    // HeadBack
    // schedule
  },
  data() {
    return {
      lineWidth: 4,
      lineColor: '#000000',
      bgColor: '#fff',
      resultImg: '',
      isCrop: false, //	是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      reload: false,
      esignHeight: 812,
      disable: false
    }
  },
  computed: {
    ...mapGetters(['UOAStatusGetter'])
  },
  watch: {
    UOAStatusGetter() {
      this.getData()
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    // uni.postMessage({
    //   data: {
    //     'type': 'screen-to-landscape', // 必传，告诉外壳要做什么
    //     'hash': location.hash // 必传，告诉外壳现在的路径
    //   }
    // })
  },
  mounted() {
    // 画布高度
    this.esignHeight = document.body.clientHeight
    // console.log(this.esignHeight)
    this.reload = true
    this.getData()
    const eletronSign = document.getElementById('eletronSignPage')
    window.addEventListener('resize', this.renderResize, true)
    // eletronSign.addEventListener('touchstart', function() {
    //   const imgBg = document.getElementById('bottomImg')
    //   imgBg.style.zIndex = -2
    // })
    // 禁止页面滑动
    eletronSign.addEventListener(
      'touchmove',
      function(e) {
        e.preventDefault()
        e.stopPropagation()
      },
      { passive: false }
    )
    // // 给toast添加类名
    // Toast.setDefaultOptions({
    //   className: 'tipToast'
    // })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderResize, true)
    // 给toast移除类名
    // Toast.setDefaultOptions({
    //   className: ''
    // })
  },
  methods: {
    goPath() {
      this.$router.push('/')
    },
    renderResize() {
      // 判断横竖屏
      const _this = this
      _this.reload = false
      setTimeout(() => {
        _this.reload = true
      }, 300)
    },
    getData() {
      // TODO 添加签名默认值(图片)
      if (
        // this.UOAStatusGetter.signatureStatus &&
        this.UOAStatusGetter.signatureImg
      ) {
        this.resultImg = JSON.parse(
          JSON.stringify(this.UOAStatusGetter.signatureImg)
        )
        // const imgBg = document.getElementById('bottomImg')
        // imgBg.style.zIndex = -2
      } else {
        // const imgBg = document.getElementById('bottomImg')
        // imgBg.style.zIndex = 2
      }
    },
    handleReset() {
      this.disable = false
      try {
        this.resultImg = ''
        this.$refs.esign.reset()
      } catch (error) {
      }
      // const imgBg = document.getElementById('bottomImg')
      // imgBg.style.zIndex = -2
    },
    // base64转blob
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // blob转file
    blobToFile(blob, fileName) {
      blob.lastModifiedDate = new Date()
      blob.name = fileName
      return blob
    },
    // base64转file
    dataURLtoFile(dataurl, filename) {
      var blob = this.dataURLtoBlob(dataurl)
      return this.blobToFile(blob, filename)
    },
    // 上传回显的图片
    // 照片上传
    uploadEcho() {
      protocolUOA.param_saveSignatureImg.param.signatureImg = this.resultImg
      http.postFront(protocolUOA.param_saveSignatureImg).then(response => {
        var { code, message } = response.data
        if (Number(code) === 0) {
          // this.$router.push('/agreement')
          this.$store.commit('reloadUOA')
          // TODO 已经完成签名
          this.$emit('saveSignature', this.resultImg)
        } else if (code === 'base_user_authn_045') {
          // this.$router.push('/checkPendingQm')
          // this.$store.commit('reloadOA')
          // 人工审核
          this.$EL_MESSAGE(message)
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    uploadImg(file) {
      const formData = new FormData()
      formData.append('file', file)
      this.fileList = []
      http.postFileJPG(formData).then(res => {
        console.log('uploadImg***', res)
        const { code, message, value } = res.data
        if (code === 0 || code === '0') {
          // console.log(value)
          // this.videoImage.push(value)
          protocolUOA.param_saveSignatureImg.param.signatureImg = value
          http.postFront(protocolUOA.param_saveSignatureImg).then(response => {
            var { code, message } = response.data
            if (Number(code) === 0) {
              // this.$router.push('/agreement')
              this.$store.commit('reloadUOA')
              // TODO 已经完成签名
              this.$emit('saveSignature', protocolUOA.param_saveSignatureImg.param.signatureImg)
            } else if (code === 'base_user_authn_045') {
              // this.$router.push('/checkPendingQm')
              // this.$store.commit('reloadOA')
              this.$EL_MESSAGE(message)
            } else {
              this.$EL_MESSAGE(message)
              // // ! 测试完成的情况
              // this.$store.commit('reloadUOA')
              // // TODO 已经完成签名
              // this.$emit('saveSignature', protocolUOA.param_saveSignatureImg.param.signatureImg)
            }
          })
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    handleGenerate() {
      if (this.disable) {
        return
      }
      this.disable = true
      if (this.resultImg) {
        this.uploadEcho()
      } else {
        this.$refs.esign
          .generate()
          .then(res => {
            // console.log(this.resultImg, 'this.resultImg')
            this.rotateBase64Img(res, 0, this.rotateBase64ImgCallback)
          })
          .catch(err => {
            this.disable = false
            console.log(err, '电子签名报错原因') // 画布没有签字时会执行这里 'Not Signned'
            // this.$toast('请输入电子签名')
            this.$EL_MESSAGE('请书写签名')
          })
      }
    },
    rotateBase64ImgCallback(res) {
      const file = this.dataURLtoFile(res, 'pm.jpg')
      const formateData = new FormData()
      formateData.append('uploadFile', file, 'pm.jpg')
      this.uploadImg(formateData.get('uploadFile'))
    },
    // base64图片旋转
    rotateBase64Img(src, edg, callback) {
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      var imgW // 图片宽度
      var imgH // 图片高度
      var size // canvas初始大小
      if (edg % 90 !== 0) {
        console.error('旋转角度必须是90的倍数!')
        this.$EL_MESSAGE('旋转角度必须是90的倍数!')
      }
      edg < 0 && (edg = (edg % 360) + 360)
      const quadrant = (edg / 90) % 4 // 旋转象限
      const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 } // 裁剪坐标
      var image = new Image()
      image.crossOrigin = 'anonymous'
      image.src = src
      image.onload = function() {
        imgW = image.width
        imgH = image.height
        size = imgW > imgH ? imgW : imgH
        canvas.width = size * 2
        canvas.height = size * 2
        switch (quadrant) {
          case 0:
            cutCoor.sx = size
            cutCoor.sy = size
            cutCoor.ex = size + imgW
            cutCoor.ey = size + imgH
            break
          case 1:
            cutCoor.sx = size - imgH
            cutCoor.sy = size
            cutCoor.ex = size
            cutCoor.ey = size + imgW
            break
          case 2:
            cutCoor.sx = size - imgW
            cutCoor.sy = size - imgH
            cutCoor.ex = size
            cutCoor.ey = size
            break
          case 3:
            cutCoor.sx = size
            cutCoor.sy = size - imgW
            cutCoor.ex = size + imgH
            cutCoor.ey = size + imgW
            break
        }
        ctx.translate(size, size)
        ctx.rotate((edg * Math.PI) / 180)
        ctx.drawImage(image, 0, 0)
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        )
        if (quadrant % 2 === 0) {
          canvas.width = imgW
          canvas.height = imgH
        } else {
          canvas.width = imgH
          canvas.height = imgW
        }
        ctx.putImageData(imgData, 0, 0)
        callback(canvas.toDataURL('image/jpeg'))
      }
    }
  }
}
</script>
<style lang="scss">
.eletronSignPage {
  background-color: #fff;
  // height: 100vh;
  overflow: hidden;
.titleEle{
  background-color:rgb(229, 236, 242);
  height:40px;
  line-height: 40px;
  font-size:22px;
  color:#000;
  text-align:center;
  position: relative;
  .backIcon{
    position: absolute;
    right: 16px;
  }
}
  .eletronSignPageBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgb(229, 236, 242);
    padding-top: 20px;
    .headWrap{
      height: 41px;
      position: relative;
      span{
        font-size:22px;
      }
      img{
        height: 31px;
        margin-top: -13vh;
      }

    }
    .backImg{
        height: 38px;
        // -webkit-transform: rotate(90deg);
        // transform: rotate(90deg);
      }
    // .backIcon{
    //   color:#0A4CB3;
    //   top: 5px;
    //   z-index:9999;
    //   -webkit-transform: rotate(-90deg);
    //   transform: rotate(-90deg);
    //   position: absolute;
    // }
    .backF{
      position: relative;
    }
    .bottomBtn {
      // padding: 15vh 5px 25vh;
    }
  }
  .backF {
    canvas {
      // height: 100vh  !important;
      background: #fff !important;
      border-radius: 10px;
      overflow: hidden;
    }

  }
  .resultStyle,
  .bottomImg {
    position: absolute;
    width:100%;
    // -webkit-transform: rotate(90deg);
    // transform: rotate(90deg);
  }
  .bottomImg {
    width: 100%;
    height: 100%;
    z-index: 2;
    max-height: 230px;
  }
  .bottomBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    div {
      width: 100px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;
    }
    .nextStepBtn{
        background-color:transparent !important;
      }
    // .cancleBtn {
    //   // border: 1px solid #0A4CB3;
    //   // color: #0A4CB3;
    //   // font-size: 16px;
    // }
  }
}
</style>
<style>
.tipToast {
  /* -webkit-transform: rotate(90deg);
  transform: rotate(90deg); */
  left: 36%;

}
.tipToast .van-toast__text{
    font-size:18px;
  }
</style>
