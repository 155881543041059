<template>
  <div style="width: 100%;height: 100%;">
    <div class="personalCenterContainer">
      <!-- <div class="titles">
        <i
          class="el-icon-arrow-left"
          style="font-size: 18px;cursor:pointer;"
          @click="goBack"
        ></i>
        身份认证
      </div> -->
      <div style="height: 20px;width:100%"></div>
      <div style="padding: 10px;">
        <div class="userMessage">
          <!-- <el-button type="primary" @click="baiduBack()" v-if="isXSYD"
            >上一步</el-button
          > -->
          <div class="titless">
            <div class="returnType">
              <!-- <el-button
                size="mini"
                id="arrowLeft"
                icon="el-icon-arrow-left"
                circle
                @click="baiduBack"
              ></el-button> -->
              <i
                class="el-icon-arrow-left"
                style="cursor:pointer"
                @click="baiduBack"
              ></i>
              <span @click="baiduBack" style="cursor:pointer" class="typeText"
                >返回</span
              >
            </div>
            <div class="userMesItem">
              <span>登录账号: {{ userMes.tel }}</span>
            </div>
          </div>
        </div>
        <div class="qrC" v-loading="isShows">
          <div class="qrcText">请先进行人脸识别</div>
          <div id="qrCode" ref="qrCodeDiv"></div>
          <div
            style="margin-top: 15px;font-weight: 700;color: red;font-size: 18px"
          ></div>
          <div style="margin-top: 15px;font-size: 18px;text-align: center;">
            <!-- <el-button type="primary" @click="baiduBack()" v-if="isXSYD"
              >上一步</el-button
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import enterpriseAuthDialog from "../com/enterpriseAuthDialog";
// import personalCertificate from "../com/personalCertificate";
import QRCode from "qrcodejs2-fix";
export default {
  props: {
    // 当前页面是否是新手引导
    isXSYD: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  data() {
    return {
      identityFlag: localStorage.getItem("identityFlag_w") || "A",
      createCodeShow: false,
      cellphone: "",
      lst_flag: window.g.LST_FLAG,
      btnDisabled: false,
      isFlag3: false,
      recordFlag: false,
      isFlagss: false,
      isUserShow: false,
      isShows: false,
      isTwo: false,
      dataO: {},
      dataP: {},
      BDAuthentication: {
        idCardNumber: "",
        name: "",
        userId: null
      },
      isBaiDuRZ: false,
      timer: null,
      activeName: "first",
      inviteCode: null,
      isNotPass: false,
      audit: {
        memberNote: null,
        riskNote: null
      },
      returnNote: "", // 驳回理由
      approveState: "", // 认证状态
      approveStateFlag: 0, // 按钮展示状态
      datas: {},
      userMes: {},
      isLoding: true,
      userImage: require("../../../assets/images/244.png")
    };
  },
  components: {
    // enterpriseAuthDialog,
    // personalCertificate
  },
  created() {
    // this.param_getIdentificationInfo();
  },
  mounted() {
    // var that = this;
    this.userMes = this.$store.state.common.sessionInfo;
    this.param_getVerifyToken();
    this.searchRecord();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    baiduBack() {
      this.certificationTypeSave(null);
    },
    // 选择新手引导类型
    certificationTypeSave(type) {
      protocol.param_certificationTypeSave.param.auditType = type;
      http.postFront(protocol.param_certificationTypeSave).then(response => {
        var { code, message } = response.data;
        if (Number(code) === 0) {
          this.$emit("badiuBack");
          //   this.$router.push('/joinNonEnterprise')
        } else {
          Toast(message);
        }
      });
    },
    // 生成邀请码
    createInviteCode() {
      if (this.cellphone && this.cellphone.match(/^1\d{10}$/)) {
        protocol.param_generateCode.param.cellphone = this.cellphone;
        protocol.param_generateCode.param.companyId = 8;
        http.postFront(protocol.param_generateCode).then(response => {
          const { code, message } = response.data;
          if (Number(code) === 0) {
            this.cellphone = null;
            this.$toast(message);
          } else {
            this.$toast(message);
          }
        });
      } else {
        this.$toast("请输入正确格式的手机号码");
      }
    },
    goBack() {
      this.$router.push("/userOrSafety");
    },
    // 查询个人企业绑定记录
    searchRecord() {
      http.postFront(protocol.param_getBaseUserBindByUserId).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          console.log("企业绑定记录", value);
          if (value) {
            this.recordFlag = true;
          } else {
            this.recordFlag = false;
          }
        }
      });
    },
    authEnterprise() {
      this.btnDisabled = true;
      if (this.inviteCode) {
        protocolFwd.param_bindCertification.param.code = this.inviteCode;
        http
          .postFrontLST(protocolFwd.param_bindCertification)
          .then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.searchRecord();
              this.btnDisabled = false;
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              // window.setTimeout(() => {
              //   this.$router.push('/personalCenter/enterprise');
              // }, 1000);
            } else {
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
              this.btnDisabled = false;
            }
          });
      } else {
        this.btnDisabled = false;
        this.$EL_MESSAGE("请输入邀请码");
      }
    },
    // 认证状态查询
    getIdentificationStatus() {
      this.isFlag3 = false;
      console.log(this.isBaiDuRZ, "isBaiDuRZ");
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            value.personCertType = 1;
            value.orgCertType = 1;
            this.datas = value;
            // this.param_getIdentificationInfo()
            if (value == null) {
              this.approveState = "未认证";
              this.approveStateFlag = 0;
            } else {
              // 认证类型（O:组织认证 P:个人认证）
              if (value.auditType == "O") {
                // this.flag1 = false
                this.approveStateFlag = 2;
                this.activeName = "second";
                if (value.auditStatus == 1) {
                  this.createCodeShow = true;
                }
              } else {
                this.approveStateFlag = 1;
                this.activeName = "first";
                if (value.auditStatus == 0) {
                  this.approveState = "未审核";
                } else if (value.auditStatus == 1) {
                  this.approveState = "已认证";
                  this.isUserShow = true;
                } else if (value.auditStatus == 2) {
                  this.approveState = "已驳回";
                  this.returnNote = value.note;
                }
                // this.enterpriseCertificationMes()
              }
              // 审核状态（0:未审核 1:已认证 2:已驳回）
              if (value.auditStatus == 0) {
                this.approveState = "未审核";
              } else if (value.auditStatus == 1) {
                this.approveState = "已认证";
                this.isFlag3 = true;
              } else if (value.auditStatus == 2) {
                this.approveState = "已驳回";
                this.returnNote = value.note;
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // 获取百度认证信息
    param_getIdentificationInfo() {
      this.isFlagss = false;
      const _this = this;
      http.postFront(protocol.param_getIdentificationInfo).then(response => {
        const { code, value } = response.data;
        if (code == 0) {
          if (value) {
            console.log("查询成功");
            this.isBaiDuRZ = false;
            this.isFlagss = false;
            this.BDAuthentication = value;
            this.getIdentificationStatus();
            clearInterval(_this.timer);
            _this.timer = null;
          } else {
            console.log("查询失败");
            this.isBaiDuRZ = true;
            this.isFlagss = true;
            // this.$EL_MESSAGE(message)
          }
        } else {
        }
      });
    },
    // 获取token跳转百度二维码
    param_getVerifyToken() {
      if (this.isBaiDuRZ && this.isBaiDuRZ != 1000) {
        return;
      }
      const _this = this;
      this.isShows = true;
      http.postFront(protocol.param_getVerifyToken).then(response => {
        const { code, message, value } = response.data;
        if (code == 0 && value) {
          _this.token = value.result.verifyToken;
          _this.$nextTick(function() {
            this.isShows = false;
            _this.bindQRCode();
          });
        } else {
          _this.$EL_MESSAGE(message);
        }
      });
    },
    // 二维码加载
    bindQRCode: function() {
      if (this.$refs.qrCodeDiv && this.$refs.qrCodeDiv.innerHTML) {
        this.$refs.qrCodeDiv.innerHTML = "";
      }
      if (this.$refs.qrCodeDiv1 && this.$refs.qrCodeDiv1.innerHTML) {
        this.$refs.qrCodeDiv1.innerHTML = "";
      }
      const token = this.token;
      const successUrl = window.g.BAIDU_SUCCESS_URL;
      const failedUrl = window.g.BAIDU_FAILED_URL;
      this.bdurl = `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`;
      new QRCode(this.$refs.qrCodeDiv, {
        text: `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`,
        width: 200,
        height: 200,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      });
      new QRCode(this.$refs.qrCodeDiv1, {
        text: `https://brain.baidu.com/face/print/?token=${token}&successUrl=${successUrl}${this.userMes.userId}&failedUrl=${failedUrl}`,
        width: 200,
        height: 200,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      });
      this.isShows = false;
    },
    handleClick() {
      // this.getIdentificationStatus()
    },
    // 企业信息认证
    showEnterpriseAuthDialog() {
      this.$refs.enterpriseAuthDCialog.showDialogs();
    },
    // 个人信息认证
    showPersonalCertificate() {
      this.$refs.personalCertificate.showDialog();
    },
    // 企业认证查询(二次)
    enterpriseCertificationMes() {
      http.postFront(protocol.param_enterpriseCertification).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          if (!value) {
            // 未查询到认证信息
            this.flag1 = true;
            // this.approveStateFlag = 2
            this.activeName = "first";
            this.isTwo = true;
          } else {
            this.dataO = value;
          }
        }
      });
    },

    reLoad() {
      this.getIdentificationStatus();
      this.$emit("reLoad");
      this.searchRecord();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .is-active {
  color: #0193de !important;
}
/deep/ .el-tabs__active-bar {
  background-color: #0193de;
}
.personalCenterContainer {
  background-color: #fff;
  //   width: 1200px;
  width: 100%;
  margin: 0 auto;
  line-height: 32px;
  height: 100%;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.enterpriseAuthContainer {
  // padding: 35px 0;
  // border: 1px solid #ccc;
}
.enterpriseAuthContainer .alreadyAuth {
  padding: 20px 0;
  line-height: 35px;
  border-right: 1px solid #f2f2f2;
}
.enterpriseAuthContainer .alreadyAuth .inviteCode {
  margin: 10px 0;
}
.enterpriseAuthContainer .notAuth {
  padding: 20px 0;
}
.enterpriseAuthContainer .notAuth .enterpriseAuthButton {
  margin: 10px 0;
}
.auditResult {
  margin-top: 15px;
}
.qrC {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  .qrcText {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }
}
.inviteCode {
  margin: 10px 0;
}
.titless {
  display: flex;
  justify-content: space-between;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
.returnType {
  // display: flex;
  // align-items: center;
  margin-bottom: 10px;
  .typeText {
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
