<template>
  <div class="signBank">
    <div class="signBankTitle">
      <div class="bankTips">
        <span class="title">*温馨提示：</span>
        <span class="desc">签约时间为交易日{{ tradingTime }}；</span>
        <span class="desc" v-if="isQyInfo == 0 || isQyInfo == 9 || isQyInfo == 8">请先进行信息申报,再进行签约</span>
        <span class="desc" v-if="isBankFlag != ''">{{ isBankFlag | BankSignStatus }}</span>
        <span class="desc" v-if="isBankFlag == 1 || isBankFlag == 13">初始资金密码为123456,请及时修改资金密码!</span>
        <span class="desc" v-if="isBankFlag == 13">请去资产-入金记录获取第一次入金流水号,并填入对应金额!</span>
      </div>
      <!-- <div class="mySignBank" style="padding-left:90px">
        <p class="tip" v-if="isQyInfo == 0 || isQyInfo == 9 || isQyInfo == 8">
          请先进行信息申报,再进行签约
        </p>
        <p class="tip" v-if="isBankFlag != ''">
          {{ isBankFlag | BankSignStatus }}
        </p>
        <p class="tip" v-if="isBankFlag == 1 || isBankFlag == 13">
          初始资金密码为123456,请及时修改资金密码!
        </p>
        <p class="tip" v-if="isBankFlag == 13">
          请去资产-入金记录获取第一次入金流水号,并填入对应金额!
        </p>
      </div> -->
    </div>
    <!-- v-if="isNextStep" -->
    <!-- v-if="isNextStep" -->
    <div class="signBankContent"  v-if="isNextStep">
      <el-form
        ref="bankFrom"
        :model="bankFrom"
        :rules="bankRules"
        label-position="top"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="银行卡号" prop="bankAcc">
              <el-input
                v-model="bankFrom.bankAcc"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行列表" prop="bankShortNum">
              <el-select
                class="width-100"
                filterable
                v-model="bankFrom.bankShortNum"
                placeholder="请选择银行"
                :disabled="isCardDisable"
              >
                <el-option
                  v-for="item in searchBankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 对公 且 为农业银行 -->
          <el-col :span="12" v-if="isProvinces && isPerComType == '02'">
            <el-form-item label="省市代码" prop="cityNo">
              <el-select
                class="width-100"
                filterable
                v-model="bankFrom.cityNo"
                placeholder="请选择省市代码"
                :disabled="isCardDisable"
              >
                <el-option
                  v-for="item in searchCityOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="inputShow">
            <el-form-item label="开户行号" prop="bindBankNo">
              <el-input
                v-model="bankFrom.bindBankNo"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="inputShow">
            <el-form-item label="开户行名" prop="bindBankName">
              <el-input
                v-model="bankFrom.bindBankName"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="submitButton">
          <el-button
            type="primary"
            v-if="isShowSuccess"
            :disabled="isDisable"
            @click="submitBankApply()"
            >提交</el-button
          >
          <el-button
            type="primary"
            v-if="isPerComType == '02' && isBank"
            @click="golden()"
            >打款验证</el-button
          >
        </div>
      </el-form>
    </div>

    <!-- 打款验证弹窗 -->
    <goldenDialog ref="goldenDialog" @reLoad="pollingSign()" />
  </div>
</template>
<script>
import goldenDialog from "../capitalCenter/com/goldenDialog";
import { mapGetters, mapActions } from "vuex";
import md5 from "js-md5";
export default {
  components: {
    goldenDialog
  },
  data() {
    return {
      tradingTime: window.g.START_TRADING_TIME,
      bankFrom: {
        bankAcc: "", // 绑定账号，绑定银行卡号或结算账号
        bindBankNo: "", //绑定账户开户行号
        bindBankName: "", //绑定账户开户行名  bindMobileNo : "",//银行卡手机号码，绑定银行卡的预留手机号仅个人会员有效(仅绑卡的时候传入)
        cityNo: "",
        bankShortNum: ""
      },
      bankRules: {
        bankAcc: [
          { required: true, message: "请输入绑定银行卡号", trigger: "change" }

          // {
          //   pattern: /^(95599|62284)\d{4,24}$/,
          //   message: "请输入以95599或者62284开头的9到29位的农业银行卡号",
          //   trigger: "blur"
          // }
        ],
        bindBankNo: [
          {
            required: true,
            message: "请输入绑定账户开户行号",
            trigger: "change"
          },
          {
            pattern: /\b\d{12}\b/,
            message: "请输入12位开户行行号",
            trigger: "blur"
          }
        ],
        bindBankName: [
          { required: true, message: "请输入账户开户行名", trigger: "change" }
        ],
        bankShortNum: [
          { required: true, message: "请选择银行", trigger: "change" }
        ],
        cityNo: [
          { required: true, message: "请选择省市代码", trigger: "change" }
        ]
      },
      searchCityOptions: [
        { value: "01", label: "总行" },
        { value: "02", label: "天津" },
        { value: "03", label: "上海(03)" },
        { value: "04", label: "山西" },
        { value: "05", label: "内蒙" },
        { value: "06", label: "辽宁" },
        { value: "07", label: "吉林" },
        { value: "08", label: "黑龙江" },
        { value: "09", label: "上海(09)" },
        { value: "10", label: "江苏" },
        { value: "11", label: "北京" },
        { value: "12", label: "安徽" },
        { value: "13", label: "福建" },
        { value: "14", label: "江西" },
        { value: "15", label: "山东" },
        { value: "16", label: "河南" },
        { value: "17", label: "湖北" },
        { value: "18", label: "湖南" },
        { value: "19", label: "浙江" },
        { value: "20", label: "广西" },
        { value: "21", label: "海南" },
        { value: "22", label: "四川" },
        { value: "23", label: "贵州" },
        { value: "24", label: "云南" },
        { value: "25", label: "西藏" },
        { value: "26", label: "陕西" },
        { value: "27", label: "甘肃" },
        { value: "28", label: "青海" },
        { value: "29", label: "宁夏" },
        { value: "30", label: "新疆" },
        { value: "31", label: "重庆" },
        { value: "34", label: "大连" },
        { value: "38", label: "青岛" },
        { value: "39", label: "宁波" },
        { value: "40", label: "厦门" },
        { value: "41", label: "深圳" },
        { value: "44", label: "广东" },
        { value: "50", label: "河北" },
        { value: "97", label: "香港" }
      ],
      searchBankOptions: [],
      // searchBankOptions: [
      //   {
      //     label: "农业银行",value: "04"
      //   }
      // ],
      isPerComType: "", // 区分是否个人还是公司
      isProvinces: false, // 是否显示省市区代码展示  银行是农业银行  而且对公才展示填省市代码
      isShowForm: null,
      bankInfo: {},
      isQyInfo: "", // 信息申报状态
      isBankFlag: "",
      tableData: [],
      isShowSuccess: false, // 提交按钮展示判断
      isShowFindResult: false,
      isCheckedBank: null,
      isNextStep: false, // 是否展示下一步
      isCardDisable: false,
      farming: "",
      isBank: false, // 打卡验证
      routerTit: "签约",
      isDisable: false,
      timerSign: null,
      inputShow: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch: {
    "bankFrom.bankShortNum": {
      handler(val, oldVal) {
        console.log(val);
        if (String(val) === "04" || val === "农业银行") {
          this.isProvinces = true;
        } else {
          this.isProvinces = false;
          this.bankFrom.cityNo = "";
        }
      }
    }
  },
  mounted() {
    this.getIdentificationStatus();
    this.getQueryBankId();
    this.getfindTrader();
    // this.getSignInfo();
    // this.timerSign = setInterval(() => {
    //   this.getSignInfo();
    // }, window.g.DETECTIONLOGIN_TIME)
  },
  beforeDestroy() {
    if (this.timerSign) {
      clearInterval(this.timerSign);
      this.timerSign = null;
    }
  },
  methods: {
    // 认证状态查询
    getIdentificationStatus() {
      this.isFlag3 = false;
      console.log(this.isBaiDuRZ, "isBaiDuRZ");
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            value.personCertType = 1;
            value.orgCertType = 1;
            this.datas = value;
            // this.param_getIdentificationInfo()
            if (value == null) {
              this.inputShow = false;
            } else {
              // 审核状态（0:未审核 1:已认证 2:已驳回）
              if (value.auditStatus == 0) {
              } else if (value.auditStatus == 1) {
                if (value.auditType == "O") {
                  this.inputShow = true;
                } else {
                  this.inputShow = false;
                }
              } else if (value.auditStatus == 2) {
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    getQueryBankId() {
      http.postFrontMarket(protocolFwd.param_queryBankId).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          const obj = value;
          const nameArray = [];
          // const newObj = JSON.parse(obj);
          const newObjKey = Object.keys(obj);
          const newObjValue = Object.values(obj);
          if (newObjKey.length != 0) {
            for (let i = 0; i < newObjKey.length; i++) {
              const obj1 = {};
              obj1.value = newObjKey[i];
              obj1.label = newObjValue[i];
              nameArray.push(obj1);
            }
            this.searchBankOptions = nameArray;
            // console.log(nameArray,'xxxx')
          }
          this.getSignInfo();
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 信息上报状态查询
    getfindTrader() {
      http.postFrontMarket(protocolFwd.param_findTrader).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          if (value) {
            //  1：交易商正常，  9:交易商开户在途  8:交易商开户失败
            if (value.status == 1) {
              this.isNextStep = true;
            }
            if (value.status == 9 || value.status == 8) {
              this.isNextStep = false;
            }
            this.isQyInfo = value.status;
            this.isPerComType = value.type;
            this.farming = value.openBankId;
            // 解约后银行卡号默认展示申报里的
            localStorage.setItem("bankAcc", value.bankAccountId);

            // if (value.openBankId == "04") {
            //   this.isProvinces = true;
            // }
          } else {
            this.isQyInfo = 0;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSignInfo() {
      http.postFrontMarket(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (value) {
          this.isBankFlag = value.signFlag;
          // 处理解约后银行卡号默认展示
          if (value.bankAccount) {
            this.bankFrom.bankAcc = value.bankAccount;
          } else {
            this.bankFrom.bankAcc = localStorage.getItem("bankAcc");
          }
          this.bankFrom.bindBankNo = value.openBankNumber;
          this.bankFrom.bindBankName = value.openBankName;

          // 处理省市县
          this.searchCityOptions.forEach(item => {
            if (item.value == value.fundNo) {
              this.bankFrom.cityNo = item.label;
            }
          });
          // 处理银行类型
          this.searchBankOptions.forEach(element => {
            if (value.crossFlag) {
              if (element.value == value.crossFlag) {
                this.bankFrom.bankShortNum = element.label;
              }
            } else {
              if (element.value == localStorage.getItem("openBankId")) {
                this.bankFrom.bankShortNum = element.label;
              }
            }

            // if (element.value == '04') {
            //   this.bankFrom.bankShortNum = element.label;
            // }
          });

          // 清除其他状态下定时器
          if (
            value.signFlag == 0 ||
            value.signFlag == 1 ||
            value.signFlag == 2 ||
            value.signFlag == 5 ||
            value.signFlag == 6 ||
            value.signFlag == 8 ||
            value.signFlag == 13
          ) {
            clearInterval(this.timerSign);
            this.timerSign = null;
          }

          if (value.signFlag == 0 || value.signFlag == 5) {
            // 0 未签约  5:绑卡出错
            this.isShowSuccess = true;
            this.isCardDisable = false;
            this.bankFrom.bindBankNo = "";
            this.bankFrom.bindBankName = "";
          } else if (value.signFlag == 1) {
            // 1：签约 13：机构绑卡成功
            this.isShowSuccess = false;
            this.isCardDisable = true;
            this.$emit("signFlagReload");
          } else if (value.signFlag == 2) {
            // 2解约成功 == > 可绑卡
            // this.isCardDisable = true;
            this.isShowSuccess = true;
          } else if (
            value.signFlag == 3 ||
            value.signFlag == 4 ||
            value.signFlag == 7 ||
            value.signFlag == 11 ||
            value.signFlag == 12
          ) {
            // 3：签约在途  4：解约在途 7:解绑在途  11:绑卡在途 12 : 打款验证在途
            this.isShowSuccess = false;
            this.isCardDisable = true;
          } else if (value.signFlag == 6) {
            // 6 : 打款验证出错
            this.isShowSuccess = false;
            this.isCardDisable = true;
            this.isBank = true;
          } else if (value.signFlag == 8) {
            // 8 解绑出错
            this.isCardDisable = true;
          } else if (value.signFlag == 13) {
            // 13 机构绑卡成功
            this.isShowSuccess = false;
            this.isCardDisable = true;
            this.isBank = true;
          }
        } else {
          this.isNextStep = false;
        }
      });
    },
    // 打款验证
    golden() {
      this.$refs.goldenDialog.showDialog();
    },
    // 打款验证返回后轮询
    pollingSign() {
      this.isBank = false;
      let that = this;
      if (this.timerSign) {
        clearInterval(this.timerSign);
        this.timerSign = null;
      }
      this.timerSign = setInterval(() => {
        that.getSignInfo();
      }, window.g.DETECTIONLOGIN_TIME);
    },
    submitBankApply() {
      this.$refs["bankFrom"].validate(valid => {
        if (valid) {
          this.isDisable = true;
          protocolFwd.param_userBindCard.param = this.bankFrom;
          // if(this.farming == '04'){
          //   protocolFwd.param_userBindCard.param.cityNo = this.cityNo
          // }
          // 处理银行类型
          this.searchBankOptions.forEach(element => {
            if (element.label == this.bankFrom.bankShortNum) {
              protocolFwd.param_userBindCard.param.bankShortNum = element.value;
            }
          });
          protocolFwd.param_userBindCard.param.traderID = this.$store.state.common.sessionInfo.userId;
          if (!this.inputShow) {
            protocolFwd.param_userBindCard.param.bindBankNo = null;
            protocolFwd.param_userBindCard.param.bindBankName = null;
          }
          console.log(protocolFwd.param_userBindCard.param);
          let that = this;
          http
            .postFrontMarket(protocolFwd.param_userBindCard)
            .then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                this.$emit("reload");
                if (value.code == 0) {
                  // 隐藏提交你按钮
                  this.isShowSuccess = false;
                  this.isCardDisable = true;
                  this.isDisable = false;
                  // that.getSignInfo();

                  // 提交后状态轮询
                  if (this.timerSign) {
                    clearInterval(this.timerSign);
                    this.timerSign = null;
                  }
                  this.timerSign = setInterval(() => {
                    that.getSignInfo();
                  }, window.g.DETECTIONLOGIN_TIME);
                  this.$EL_MESSAGE(message);
                  // _this.$router.push('/capitalCenter/signList');
                } else {
                  // this.this.isShowSuccess = true
                  this.isCardDisable = false;
                  this.isDisable = false;
                  this.$EL_MESSAGE(value.message);
                }
              } else {
                that.$EL_MESSAGE(message);
                that.isDisable = false;
              }
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.signBank {
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  overflow: hidden;
  overflow-y: auto;
  // padding-top: 20px;
}
.signBank .mySignBank {
  float: left;
  line-height: 40px;
}
.signBankTitle {
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    width: 1200px;
    /* margin: 20px auto 0; */
    // padding: 10px 40px;
    box-sizing: border-box;
}
.signBankTitle .mySignBank p {
  font-size: 16px;
}
.signBankTitle .mySignBank .tip {
  font-size: 18px;
  // color: #cf4640;
  color: red;
  font-weight: 700;
}
.submitButton {
  text-align: center;
}
.signBankContent {
  width: 1100px;
  margin: 0 auto;
  background-color: #fff;
  // background-color: rgba(242,246,250,.5);
  border-radius: 2px;
  box-sizing: border-box;
  // padding: 10px 40px 20px;
}
.tipsContent {
  margin-bottom: 15px;
  line-height: 30px;
  color: #9999a2;
}
.signStatus {
  margin: 10px 0;
}
.bankInfo {
  line-height: 35px;
}
.bankInfo p {
  font-size: 14px;
  color: #333333;
}
.backTable {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.chooseIcon i {
  font-size: 20px;
  cursor: pointer;
}
.chooseIcon i.fa-circle-thin {
  color: #9999a2;
}
.chooseIcon i.fa-check-circle {
  color: $commonThemeColor;
}
.bankTips {
  font-weight: 500;
  font-size: 16px;
  color: #1347D3;
  background: linear-gradient(90deg, #D2DCF7 0%, #FFFFFF 100%);
  border-radius: 8px 8px 0px 0px;
  font-weight: 700;
  font-size: 18px;
  padding: 12px 80px;
}
.desc {
  font-size: 18px;
}
.title {
  font-size: 18px;
}
</style>
