<template>
  <div style=" width: 100%;height: 100%;">
    <div class="personalCenterContainer">
      <!-- <div style="height: 20px;width:100%"></div> -->
      <div>
        <div class="userMessage">
          <div class="titless">
            <div class="userMesItem">
              <!-- <div>
                <div
                  class="returnType"
                  v-if="
                    approveState === '未认证' && identityFlag == 'O' && isXSYD
                  "
                >
                  <i
                    class="el-icon-arrow-left"
                    style="cursor:pointer"
                    @click="enterAuthBack"
                  ></i>
                  <span
                    @click="enterAuthBack"
                    style="cursor:pointer"
                    class="typeText"
                    >返回</span
                  >
                </div>
              </div>
              <div>
                <span>登录账号: {{ userMes.tel }}</span>
                <span v-if="approveState">
                  <i style="display:inline-block;width:50px;"></i> 认证状态:
                  <span style="color:red;"> {{ approveState }}</span></span
                >
              </div> -->
              <!-- <span class="backBtn">
                <el-button
                  type="primary"
                  @click="enterAuthBack()"
                  v-if="
                    approveState === '未认证' && identityFlag == 'O' && isXSYD
                  "
                >
                  上一步
                </el-button>
              </span> -->
            </div>
            <!-- 加入企业 -->
            <div
              class="alreadyAuth"
              v-if="firmListGetter.length!=0&&firmListGetter[0].marketId==18&&isUserShow && !recordFlag && lst_flag"
            >
              <p>我的公司已被认证？</p>
              <p>请输入邀请码加入成为员工账号</p>
              <div class="inviteCode">
                <el-row :gutter="20">
                  <el-col :span="10">
                    <el-input
                      v-model="inviteCode"
                      placeholder="请输入邀请码"
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="primary"
                      :disabled="btnDisabled"
                      @click="authEnterprise()"
                      >验证并加入</el-button
                    >
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 生成邀请码 -->
            <div class="alreadyAuth" v-if="createCodeShow && lst_flag">
              <div>生成邀请码</div>
              <div class="inviteCode">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-input
                      v-model="cellphone"
                      placeholder="请输入手机号"
                    ></el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="primary"
                      :disabled="btnDisabled"
                      @click="createInviteCode()"
                      >生成邀请码</el-button
                    >
                  </el-col>
                </el-row>
              </div>
              <!-- <el-input class="staffAccountInput" v-model="cellphone" placeholder="请输入手机号码"></el-input> -->
              <div class="inviteCodeTime">邀请码将发送到员工手机号中</div>
            </div>
          </div>
        </div>
        <div class="enterpriseAuthContainer">
          <el-row>
            <div v-if="returnNote" class="returnsNote">
              驳回理由：{{ returnNote }}
            </div>
          </el-row>
          <!-- v-if="approveStateFlag == 1 || approveStateFlag == 0" -->
          <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              label="个人认证"
              v-if="identityFlag == 'P'"
              name="first"
            >
              <personalCertificate
                :dataTimeout="indetificationData"
                :datas="datas"
                :BDAuthentication="BDAuthentication"
                :isStep="true"
                ref="personalCertificate"
                @reLoad="reLoad()"
              />
            </el-tab-pane> -->
            <!-- v-if="approveStateFlag == 2 || approveStateFlag == 0" -->

            <!-- <el-tab-pane
              label="公司认证"
              v-if="identityFlag == 'O'"
              name="second"
            > -->
              <!-- 企业认证 -->
              <enterpriseAuthDialog
                :dataTimeout="indetificationData"
                :datas="datas"
                :isTwo="isTwo"
                :isStep="true"
                :BDAuthentication="BDAuthentication"
                ref="enterpriseAuthDCialog"
                @reLoad="reLoad()"
              />
            <!-- </el-tab-pane>
          </el-tabs> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import enterpriseAuthDialog from "../com/enterpriseAuthDialog";
import personalCertificate from "../com/personalCertificate";
import { mapGetters } from "vuex";
export default {
  props: {
    indetificationData: Object,
    BDAuthentication: Object,
    enterStatus: Number,
    identityFlag_page: String,
    // 当前页面是否是新手引导
    isXSYD: {
      type: Boolean,
      default: false,
      require: false
    }
  },
  data() {
    return {
      identityFlag: "A",
      createCodeShow: false,
      cellphone: "",
      lst_flag: window.g.LST_FLAG,
      btnDisabled: false,
      isFlag3: false,
      recordFlag: false,
      isFlagss: false,
      isUserShow: false,
      isShows: false,
      isTwo: false,
      dataO: {},
      dataP: {},

      timer: null,
      activeName: "first",
      inviteCode: null,
      isNotPass: false,
      audit: {
        memberNote: null,
        riskNote: null
      },
      returnNote: "", // 驳回理由
      approveState: "", // 认证状态
      approveStateFlag: 0, // 按钮展示状态
      datas: {},
      userMes: {},
      userImage: require("../../../assets/images/244.png")
    };
  },
  components: {
    enterpriseAuthDialog,
    personalCertificate
  },
  computed: {
    ...mapGetters(["firmListGetter"])
  },
  watch: {
    indetificationData(value) {
      if (value != {}) {
        if (value.auditStatus == 0) {
          this.approveState = "未审核";
        } else if (value.auditStatus == 1) {
          this.approveState = "已认证";
          this.isUserShow = true;
        } else if (value.auditStatus == 2) {
          this.approveState = "已驳回";
          this.returnNote = value.note;
        }
      } else {
        this.approveState = "未认证";
      }
    },
    enterStatus(val) {
      console.log(val);
      if (val == 2) {
        console.log(787878787878787);
        this.getIdentificationStatus();
      }
    },
    identityFlag_page(val) {
      this.identityFlag = val;
      if (val == "P") {
        this.activeName = "first";
      } else {
        this.activeName = "second";
      }
    }
  },
  created() {
    this.identityFlag = this.identityFlag_page;
    if (this.identityFlag_page == "P") {
      this.activeName = "first";
    } else {
      this.activeName = "second";
    }
  },
  mounted() {
    // var that = this;
    this.userMes = this.$store.state.common.sessionInfo;
    // this.searchRecord();
    this.getIdentificationStatus();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    enterAuthBack() {
      this.certificationTypeSave(null);
    },
    // 选择新手引导类型
    certificationTypeSave(type) {
      protocol.param_certificationTypeSave.param.auditType = type;
      http.postFront(protocol.param_certificationTypeSave).then(response => {
        var { code, message } = response.data;
        if (Number(code) === 0) {
          this.$emit("enterAuthBack");
          //   this.$router.push('/joinNonEnterprise')
        } else {
          Toast(message);
        }
      });
    },
    // 生成邀请码
    createInviteCode() {
      if (this.cellphone && this.cellphone.match(/^1\d{10}$/)) {
        protocol.param_generateCode.param.cellphone = this.cellphone;
        protocol.param_generateCode.param.companyId = 8;
        http.postFront(protocol.param_generateCode).then(response => {
          const { code, message } = response.data;
          if (Number(code) === 0) {
            this.cellphone = null;
            this.$toast(message);
          } else {
            this.$toast(message);
          }
        });
      } else {
        this.$toast("请输入正确格式的手机号码");
      }
    },
    goBack() {
      this.$router.push("/userOrSafety");
    },
    // 查询个人企业绑定记录
    searchRecord() {
      http.postFront(protocol.param_getBaseUserBindByUserId).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          console.log("企业绑定记录", value);
          if (value) {
            this.recordFlag = true;
          } else {
            this.recordFlag = false;
          }
        }
      });
    },
    authEnterprise() {
      this.btnDisabled = true;
      if (this.inviteCode) {
        protocolFwd.param_bindCertification.param.code = this.inviteCode;
        http
          .postFrontLST(protocolFwd.param_bindCertification)
          .then(response => {
            this.btnDisabled = false;
            const { code, message, value } = response.data;
            if (code == 0) {
              this.searchRecord();
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
              this.$emit("reLoad");
              this.getIdentificationStatusa();
              // window.setTimeout(() => {
              //   this.$router.push('/personalCenter/enterprise');
              // }, 1000);
            } else {
              this.inviteCode = null;
              this.$EL_MESSAGE(message);
            }
          })
          .catch(e => {
            this.$EL_MESSAGE(e)
            this.btnDisabled = false;
          })
      } else {
        this.btnDisabled = false;
        this.$EL_MESSAGE("请输入邀请码");
      }
    },
    // 认证状态查询
    getIdentificationStatus() {
      this.isFlag3 = false;
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            this.datas = value;
            if (value == null) {
              this.approveState = "未认证";
              this.approveStateFlag = 0;
            } else {
              // 认证类型（O:组织认证 P:个人认证）
              if (value.auditType == "O") {
                // this.flag1 = false
                this.approveStateFlag = 2;
                this.activeName = "second";
                if (value.auditStatus == 1) {
                  this.createCodeShow = true;
                }
              } else {
                this.approveStateFlag = 1;
                this.activeName = "first";
                if (value.auditStatus == 0) {
                  this.approveState = "未审核";
                } else if (value.auditStatus == 1) {
                  this.approveState = "已认证";
                  this.isUserShow = true;
                } else if (value.auditStatus == 2) {
                  this.approveState = "已驳回";
                  this.returnNote = value.note;
                }
                // this.enterpriseCertificationMes()
              }
              // 审核状态（0:未审核 1:已认证 2:已驳回）
              if (value.auditStatus == 0) {
                this.approveState = "未审核";
              } else if (value.auditStatus == 1) {
                this.approveState = "已认证";
                this.isFlag3 = true;
              } else if (value.auditStatus == 2) {
                this.approveState = "已驳回";
                this.returnNote = value.note;
              }
            }
            this.datas.personCertType = 1;
            this.datas.orgCertType = 1;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },

    handleClick() {
      // this.getIdentificationStatus()
    },
    // 企业信息认证
    showEnterpriseAuthDialog() {
      this.$refs.enterpriseAuthDCialog.showDialogs();
    },
    // 个人信息认证
    showPersonalCertificate() {
      this.$refs.personalCertificate.showDialog();
    },
    // 企业认证查询(二次)
    enterpriseCertificationMes() {
      http.postFront(protocol.param_enterpriseCertification).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          if (!value) {
            // 未查询到认证信息
            this.flag1 = true;
            // this.approveStateFlag = 2
            this.activeName = "first";
            this.isTwo = true;
          } else {
            this.dataO = value;
          }
        }
      });
    },

    reLoad() {
      this.getIdentificationStatus();
      this.$emit("reLoad");
      this.searchRecord();
    }
  }
};
</script>
<style lang="scss" scoped>
.returnType {
  // display: flex;
  // align-items: center;
  margin-bottom: 10px;
  .typeText {
    font-size: 14px;
    margin-left: 10px;
  }
}
.userMesItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/deep/ .is-active {
  color: #0193de !important;
}
/deep/ .el-tabs__active-bar {
  background-color: #0193de;
}
.personalCenterContainer {
  background-color: #fff;
  width: 1200px;
  margin: 0;

  line-height: 32px;
  border-radius: 8px;
  // height: 100%;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.enterpriseAuthContainer {
  // padding: 35px 0;
  // border: 1px solid #ccc;
}
.enterpriseAuthContainer .alreadyAuth {
  padding: 20px 0;
  line-height: 35px;
  border-right: 1px solid #f2f2f2;
}
.enterpriseAuthContainer .alreadyAuth .inviteCode {
  margin: 10px 0;
}
.enterpriseAuthContainer .notAuth {
  padding: 20px 0;
}
.enterpriseAuthContainer .notAuth .enterpriseAuthButton {
  margin: 10px 0;
}
.auditResult {
  margin-top: 15px;
}
.qrC {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  .qrcText {
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }
}
.inviteCode {
  margin: 10px 0;
}
.titless {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
/deep/.el-step__title.is-process {
  color: #0193de !important;
}
.backBtn {
  // position: absolute;
  // right: 10px;
}
.returnsNote{
  // returnsNote
  line-height: 50px;
  padding-left: 45px;
  background-color: #ccc;
  border-radius: 8px 8px 0 0;
}
</style>
