<template>
  <div class="infoReporning">
    <div class="infoReporningTitle">
      <div class="myinfoReporning">
        <p class="tip" style="margin-bottom:-10px">
          温馨提示：信息申报时间为交易日{{ time }}
        </p>
        <span class="tip marginLeft" v-if="isQyInfo === 0">销户成功</span>
        <span class="tip marginLeft" v-if="isQyInfo == 1"
          >申报信息审核完成,请前往银行签约页面进行绑卡签约!</span
        >
        <span class="tip marginLeft" v-if="isQyInfo == 4"
          >鲁清所销户失败，销户异常!</span
        >
        <span class="tip marginLeft" v-if="isQyInfo == 5"
          >银行端销户成功，未发起鲁清所销户，销户异常!</span
        >
        <span class="tip marginLeft" v-if="isQyInfo == 7"
          >销户正在申请中,请耐心等待!</span
        >
        <span class="tip marginLeft" v-if="isQyInfo == 8"
          >申报信息审核失败,请重新填写!</span
        >
        <span class="tip marginLeft" v-if="isQyInfo == 9"
          >申报信息正在上传,请耐心等待!</span
        >
      </div>
    </div>
    <div class="infoReporningContent">
      <!-- <div v-if="!isNextStep"> -->
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="申报类型" prop="type">
              <el-radio-group v-model="form.type" disabled>
                <el-radio :label="'01'">个人</el-radio>
                <el-radio :label="'02'">公司</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用户名称"
              prop="traderName"
              v-if="form.type == '01'">
              <div style="display: flex">
                <el-input v-model="form.traderName" disabled />
                <el-button type="primary" @click="modifyTraderName('01')" v-if="modifyTraderStatus === null">修改</el-button>
                <el-button type="primary" @click="showCheckTraderNameDialog = true"  v-if="modifyTraderStatus === 1">待审核</el-button>
                <el-button type="primary" v-if="modifyTraderStatus === 2">审核通过</el-button>
                <el-button type="primary"  v-if="modifyTraderStatus === 3" @click="modifyTraderName('01')">审核驳回，查看原因</el-button>
              </div>
            </el-form-item>
            <el-form-item
              label="公司名称"
              prop="traderName"
              v-if="form.type == '02'"
            >
              <div style="display: flex">
                <el-input v-model="form.traderName" disabled />
                <el-button type="primary" @click="modifyTraderName('02')" v-if="modifyTraderStatus === null">修改</el-button>
                <el-button type="primary" @click="showCheckTraderNameDialog = true" v-if="modifyTraderStatus === 1">待审核...</el-button>
                <el-button type="primary"  v-if="modifyTraderStatus === 2">审核通过</el-button>
                <el-button type="primary"  v-if="modifyTraderStatus === 3" @click="modifyTraderName('02')">审核驳回，查看原因...</el-button>
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              label="证件类型"
              prop="credentialsTypePer"
              v-if="form.type == '01'"
            >
              <el-select
                v-model="form.credentialsTypePer"
                filterable
                placeholder="请选择证件类型"
                disabled
                class="width-100"
              >
                <el-option
                  v-for="item in searchTypeOptionsPer"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="证件类型"
              prop="credentialsTypeAuth"
              v-if="form.type == '02'"
            >
              <el-select
                v-model="form.credentialsTypeAuth"
                filterable
                placeholder="请选择证件类型"
                disabled
                class="width-100"
              >
                <el-option
                  v-for="item in searchTypeOptionsAuth"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号" prop="credentialsCode">
              <el-input v-model="form.credentialsCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile">
              <el-input
                v-model="form.mobile"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type == '02'">
            <el-form-item label="法人名称" prop="artificialPerson">
              <el-input v-model="form.artificialPerson" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type == '02'">
            <el-form-item label="法人证件类型" prop="arcredentialsType">
              <el-select
                v-model="form.arcredentialsType"
                filterable
                placeholder="请选择证件类型"
                disabled
                class="width-100"
              >
                <el-option
                  v-for="item in searchTypeOptionsPer"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type == '02'">
            <el-form-item label="法人证件号" prop="arcredentialsCode">
              <el-input v-model="form.arcredentialsCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.type == '02'">
            <el-form-item label="法人联系方式" prop="artificialContact">
              <el-input
                v-model="form.artificialContact"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="注册资本"
              prop="registCapital"
              v-if="form.type == '02'"
            >
              <el-input
                v-model="form.registCapital"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择开户行" prop="bankShortNum">
              <!-- <el-select
                v-model="form.bankShortNum"
                filterable
                placeholder="请选择开户行"
                disabled
                class="width-100"
              >
                <el-option
                  v-for="item in searchBankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <el-select
                class="width-100"
                filterable
                v-model="form.bankShortNum"
                placeholder="请选择开户行"
                :disabled="isCardDisable"
                @change="binkChange"
              >
                <el-option
                  v-for="item in searchBankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行账号(银行卡号)" prop="bankAccountID">
              <el-input
                v-model="form.bankAccountID"
                :disabled="isCardDisable"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="submitButton">
          <el-button
            type="primary"
            @click="submitApply()"
            v-if="isQyInfo == 8"
            :disabled="isDisable"
            >重新提交</el-button
          >
          <el-button
            type="primary"
            @click="submitApply()"
            v-if="isBtn && isQyInfo != 4 && isQyInfo != 5"
            :disabled="isDisable"
            >下一步</el-button
          >
          <el-button
            type="primary"
            @click="account()"
            v-if="
              isAccount &&
                (isQyBank == 0 || isQyBank == 2 || isQyBank == 5) &&
                isQyInfo != 4 &&
                isQyInfo != 5
            "
            :disabled="isDisable"
            >销户</el-button
          >
        </div>
      </el-form>
    </div>
  <!--  修改名称  -->
    <el-dialog title="交易商名称修改" :visible="showTraderNameDialog" destroyed-on-close @close="showTraderNameDialog=false">
      <el-form :model="modifyForm" ref="modifyFormRef" label-width="100px">
        <el-form-item label="驳回理由：" v-if="modifyTraderStatus === 3">
           <p style="color: #e96c6c;">{{ modifyForm.reason }}</p>
        </el-form-item>
        <el-form-item label="新名称：" prop="traderName" :rules="[ { required: true, message: '请输入交易商名称', trigger: 'change' }]">
          <el-input v-model="modifyForm.traderName" :maxlength="25" placeholder="请输入新名称" />
        </el-form-item>
        <el-form-item align="center">
          <el-button @click="showTraderNameDialog=false">取消</el-button>
          <el-button type="primary" :loading="modifyLoading" @click="confirmModify">
             {{ modifyTraderStatus === 3 ? `重新 提交` : `提交 修改` }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--  查看交易商审核名称  -->
    <el-dialog title="交易商名称信息" :visible="showCheckTraderNameDialog" destroyed-on-close @close="showCheckTraderNameDialog=false">
      <el-form :model="queryTradeNameInfo" ref="modifyFormRef" label-width="150px">
        <el-form-item label="交易商原名称：" >
          <el-input v-model="queryTradeNameInfo.traderOldName" disabled/>
        </el-form-item>
        <el-form-item label="交易商新名称：" >
          <el-input v-model="queryTradeNameInfo.traderNewName" disabled/>
        </el-form-item>
        <el-form-item label="更新时间：" >
          <el-date-picker v-model="queryTradeNameInfo.update_time" type="datetime" disabled style="width: 100%"/>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  data() {
    var isChinese = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error("证件号码不能包含中文，请重新输入!"));
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      time: window.g.START_TRADING_TIME,
      form: {
        bankShortNum: "",
        type: "", // 交易商类型 01：自然人，02：机构 【必填】
        traderID: "", // 交易商代码 【必填】(userId)
        traderName: "", // 交易商名称
        status: "1", // 交易商状态 0：注销，1：交易商正常，2：交易商冻结  写死 1
        credentialsType: "",
        credentialsTypePer: "身份证",
        credentialsTypeAuth: "营业执照",
        // "证件类型 1-身份证 2-军人军官证 3-港澳台居民通行证 " +
        // "4-中国护照 5-单位统一代码 6-未登记证件  7-暂住证 8-武警警官证 9-临时身份证 10-联名户 " +
        // "11-户口簿 12-中国居民其他证 13-军人士兵证  14-军人文职干部证  15-军人其他证件  16-武警士兵证  " +
        // "17-武警文职干部证  18-武警其他证件  19-外国护照  20-外国公民其他证件  21-重复有效证件  22-解放军士兵证  " +
        // "23-解放军文职干部证  24-解放军其它个人证件  25-武警士兵证  26-武警文职干部证  27-武警其它个人证件  " +
        // "51-法人代码证  52-组织机构代码证  53-政府机构/公共机构批文  54-外交部 外事办批文（使）  " +
        // "55-外交部 外事办批文（领）  56-外交部 外事办批文（办）  60-香港商业登记证  65-事业单位登记证  " +
        // "66-社会团体登记证  67-商业登记证（离岸）  68-营业执照  69-对公临时证件  70-其他证明文件(公司)  " +
        // "71-公司户重复有效证件  73-统一社会信用代码  80-金融机构  【必填】
        credentialsCode: "", // 证件号
        mobile: "", // 手机号
        arcredentialsType: "1", // 法人证件类型
        arcredentialsCode: "", // 法人证件号
        artificialPerson: "", // 法人名称
        artificialContact: "", // 法人联系方式
        registCapital: "", // 注册资本
        bankId: "22", // 银行ID 【必填】 写死 22
        infoReporing: "", // 银行列表选择
        bankAccountID: "" // 银行账号(银行卡号)
      },
      bankFrom: {
        bankId: 3, //
        memType: "1", // 1-对公（公司）  0-对私（个人）
        bindAcctNo: "", // 绑定账号，绑定银行卡号或结算账号
        bindBankNo: "", //绑定账户开户行号
        bindBankName: "", //绑定账户开户行名  bindMobileNo : "",//银行卡手机号码，绑定银行卡的预留手机号仅个人会员有效(仅绑卡的时候传入)
        subAcctNo: "" //资金账号
      },
      currentStatusBank: {},
      rules: {
        traderName: [
          { required: true, message: "请输入交易商名称", trigger: "change" }
        ],
        credentialsType: [
          { required: true, message: "请输入证件类型", trigger: "change" }
        ],
        credentialsTypePer: [
          { required: true, message: "请输入证件类型", trigger: "change" }
        ],
        credentialsTypeAuth: [
          { required: true, message: "请输入证件类型", trigger: "change" }
        ],
        credentialsCode: [
          { required: true, message: "请输入证件号码", trigger: "change" },
          { validator: isChinese, trigger: "blur" }
        ],
        arcredentialsType: [
          { required: true, message: "请选择法人证件类型", trigger: "blur" }
        ],
        artificialPerson: [
          { required: true, message: "请输入法人代表姓名", trigger: "change" }
        ],
        arcredentialsCode: [
          {
            required: true,
            message: "请输入法人身份证号码",
            trigger: "change"
          },
          { validator: isChinese, trigger: "blur" }
        ],
        artificialContact: [
          { required: true, message: "请输入法人联系方式", trigger: "change" }
        ],
        registCapital: [
          { required: true, message: "请输入注册资本", trigger: "change" }
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "change" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ],
        bankAccountID: [
          { required: true, message: "请输入绑定银行卡号", trigger: "change" }

          // {
          //   pattern: /^(95599|62284)\d{4,24}$/,
          //   message: "请输入以95599或者62284开头的9到29位的农业银行卡号",
          //   trigger: "blur"
          // }
        ],
        bankShortNum: [
          { required: true, message: "请选择开户行", trigger: "blur" }
        ]
      },
      searchTypeOptions: [
        //01 营业执照 06 组织机构代码 07 社会统一信用证
        { value: "1", label: "身份证" },
        { value: "68", label: "营业执照" }
      ],
      searchTypeOptionsPer: [{ value: "1", label: "身份证" }],
      searchTypeOptionsAuth: [{ value: "68", label: "营业执照" }],
      // searchTypeOptions: [
      //   //01 营业执照 06 组织机构代码 07 社会统一信用证
      //   { value: "1", label: "身份证" },
      //   { value: "2", label: "军人军官证" },
      //   { value: "3", label: "港澳台居民通行证" },
      //   { value: "4", label: "中国护照" },
      //   { value: "5", label: "单位统一代码" },
      //   { value: "6", label: "未登记证件" },
      //   { value: "7", label: "暂住证" },
      //   { value: "8", label: "武警警官证" },
      //   { value: "9", label: "临时身份证" },
      //   { value: "10", label: "联名户" },
      //   { value: "11", label: "户口簿" },
      //   { value: "12", label: "中国居民其他证" },
      //   { value: "13", label: "军人士兵证" },
      //   { value: "14", label: "军人文职干部证" },
      //   { value: "15", label: "军人其他证件" },
      //   { value: "16", label: "武警士兵证" },
      //   { value: "17", label: "武警文职干部证" },
      //   { value: "18", label: "武警其他证件" },
      //   { value: "19", label: "外国护照" },
      //   { value: "20", label: "外国公民其他证件" },
      //   { value: "21", label: "重复有效证件" },
      //   { value: "22", label: "解放军士兵证" },
      //   { value: "23", label: "解放军文职干部证" },
      //   { value: "24", label: "解放军其它个人证件" },
      //   { value: "25", label: "武警士兵证" },
      //   { value: "26", label: "武警文职干部证" },
      //   { value: "27", label: "武警其它个人证件" },
      //   { value: "51", label: "法人代码证" },
      //   { value: "52", label: "组织机构代码证" },
      //   { value: "53", label: "政府机构/公共机构批文" },
      //   { value: "54", label: "外交部 外事办批文（使）" },
      //   { value: "55", label: "外交部 外事办批文（领）" },
      //   { value: "56", label: "外交部 外事办批文（办）" },
      //   { value: "60", label: "香港商业登记证" },
      //   { value: "65", label: "事业单位登记证" },
      //   { value: "66", label: "社会团体登记证" },
      //   { value: "67", label: "商业登记证（离岸）" },
      //   { value: "68", label: "营业执照" },
      //   { value: "69", label: "对公临时证件" },
      //   { value: "70", label: "其他证明文件(公司)" },
      //   { value: "71", label: "公司户重复有效证件" },
      //   { value: "73", label: "统一社会信用代码" },
      //   { value: "80", label: "金融机构" },
      // ],
      // searchBankOptions: [
      //   {
      //     label: "农业银行",
      //     value: "04"
      //   }
      // ],
      searchBankOptions: [],
      isQyInfo: null,
      isShowForm: null,
      bankInfo: {},
      tableData: [],
      isShowFindResult: false,
      isCheckedBank: null,
      isNextStep: true, // 是否展示下一步
      isCardDisable: false,
      isBtn: true,
      isDisable: false,
      isQyBank: "",
      isAccount: false,
      routerTit: "信息申报",
      timerTrader: null,
      showTraderNameDialog: false,
      modifyLoading: false,
      modifyTraderStatus: null, // 1 待审核  2 审核通过  3 审核拒绝
      modifyForm: {
        id: null,
        type: null,
        traderName: null,
        reason: null, // 审核驳回原因
      },
      showCheckTraderNameDialog: false,
      queryTradeNameInfo: {
        traderNewName: null,
        traderOldName: null,
        status: null,
        createTime: null
      }

    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch: {
    // "form.searchType": {
    //   handler(val, oldVal) {
    //     this.isCheckedBank = null;
    //     this.form.openBankName = item.bankName;
    //     this.form.openBankNum = item.bankCode;
    //     this.isShowFindResult = false;
    //     this.tableData = [];
    //   }
    // }
    "form.bankShortNum": function() {
      setTimeout(() => {
        this.$refs["form"].validateField("bankShortNum", null);
      }, 100);
    }
  },
  mounted() {
    const auditObj = JSON.parse(sessionStorage.getItem("auditObj"));
    // 个人 ==》 姓名，身份证号
    // 公司 ==》 公司名称，营业证件号，法人名称，法人证件号
    if (auditObj.auditType === "P") {
      this.form.traderName = auditObj.name;
      this.form.credentialsCode = auditObj.certNo; // 身份证号
    } else {
      this.form.traderName = auditObj.name; // 公司名称
      this.form.artificialPerson = auditObj.legalPersonName; // 法人名称
      this.form.arcredentialsCode = auditObj.legalPersonCertCode;
      this.form.credentialsCode = auditObj.certNo;
    }
    if (sessionStorage.getItem("auditType") == "P") {
      this.form.type = "01";
    } else {
      this.form.type = "02";
    }
    this.getQueryBankId();
    this.getSignInfo();
    this.queryModifyTraderNameStatus();
    // this.getfindTrader();
    // this.timerTrader = setInterval(() => {
    //   this.getfindTrader();
    // }, window.g.DETECTIONLOGIN_TIME)
  },
  beforeDestroy() {
    if (this.timerTrader) {
      clearInterval(this.timerTrader);
      this.timerTrader = null;
    }
  },
  methods: {
    binkChange() {
      console.log(this.form.bankShortNum, "this.form.bankShortNum");
    },
    getQueryBankId() {
      http.postFront(protocolFwd.param_queryBankId).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          const obj = value;
          const nameArray = [];
          // const newObj = JSON.parse(obj);
          const newObjKey = Object.keys(obj);
          const newObjValue = Object.values(obj);
          if (newObjKey.length != 0) {
            for (let i = 0; i < newObjKey.length; i++) {
              const obj1 = {};
              obj1.value = newObjKey[i];
              obj1.label = newObjValue[i];
              nameArray.push(obj1);
            }
            this.searchBankOptions = nameArray;
            console.log(nameArray, "xxxx");
          }
          this.getfindTrader();
        }
      });
    },
    // 信息上报状态查询
    getfindTrader() {
      http.postFront(protocolFwd.param_findTrader).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          if (value) {
            this.form.type = value.type;
            // this.form.traderName = value.traderName;
            // this.form.credentialsType = value.credentialsType;
            // this.form.credentialsCode = value.credentialsCode;
            this.form.mobile = value.mobile;
            // 处理银行类型
            this.searchBankOptions.forEach(element => {
              if (element.value == value.openBankId) {
                this.form.bankShortNum = element.value;
              }
            });
            // this.form.arcredentialsType = value.arcredentialsType;
            // this.form.arcredentialsCode = value.arcredentialsCode;
            // this.form.artificialPerson = value.artificialPerson;
            this.form.artificialContact = value.artificialContact;
            this.form.registCapital = value.registCapital;
            this.form.bankAccountID = value.bankAccountId;
            // 缓存
            localStorage.setItem("bankAcc", value.bankAccountId);
            localStorage.setItem("openBankId", value.openBankId);
            this.isQyInfo = value.status;
            // 销户按钮
            if (this.isQyInfo == 1) {
              this.isAccount = true;
            }
            // 清除定时器
            if (
              value.status == "0" ||
              value.status == "1" ||
              value.status == "8"
            ) {
              clearInterval(this.timerTrader);
              this.timerTrader = null;
            }
            if (
              value.status == "1" ||
              value.status == "9" ||
              value.status == "7"
            ) {
              this.isCardDisable = true;
              this.isBtn = false;
            } else if (value.status == "8") {
              this.isBtn = false;
              this.isCardDisable = false;
            } else if (value.status == "0") {
              this.isCardDisable = false;
              this.isBtn = true;
            }
            if (value.status == "1") {
              this.$emit("reload");
            }
            //  1：交易商正常，  9:交易商开户在途  8:交易商开户失败
          } else {
            this.isQyInfo = "";
          }
        } else {
          this.isQyInfo = null;
          this.$EL_MESSAGE(message);
        }
      });
    },
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == -180642) {
          // 未签约
          this.isNextStep = false;
        } else if (Number(code) == 0) {
          this.isQyBank = value.signFlag;
          if (value.signFlag == 3) {
            //签约未绑卡
            this.isNextStep = true;
            this.bankFrom.subAcctNo = value.fundNo;
          } else if (value.signFlag == 1) {
            //签约已绑卡
            this.isNextStep = true;
            this.isCardDisable = true;
            this.bankFrom.bindAcctNo = value.bankAccount;
            this.bankFrom.bindBankNo = value.openBankNumber;
            this.bankFrom.bindBankName = value.openBankName;
            this.bankFrom.subAcctNo = value.fundNo;
          } else if (value.signFlag == 2) {
            this.isNextStep = false;
          } else if (value.signFlag == 0) {
            this.isNextStep = false;
          }
        } else {
          this.isNextStep = false;
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 销户
    account() {
      let that = this;
      this.$confirm("确认销户？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.isDisable = true;
          protocolFwd.param_traderCancellation.param.traderId = this.$store.state.common.sessionInfo.userId;
          http
            .postFront(protocolFwd.param_traderCancellation)
            .then(response => {
              const { code, message, value } = response.data;
              if (code == 0) {
                if (value.code == 0) {
                  this.isDisable = false;
                  // 销户隐藏
                  this.isAccount = false;
                  this.$EL_MESSAGE(message);
                  if (this.timerTrader) {
                    clearInterval(this.timerTrader);
                    this.timerTrader = null;
                  }
                  that.timerTrader = setInterval(() => {
                    that.getfindTrader();
                  }, window.g.DETECTIONLOGIN_TIME);
                } else {
                  this.isDisable = false;
                  this.isAccount = true;
                  this.$EL_MESSAGE(value.message);
                }
              } else {
                this.$EL_MESSAGE(message);
                this.isDisable = false;
              }
            });
        })
        .catch(() => {});
    },
    submitApply() {
      // console.log(this.form, "form");
      let that = this;
      console.log(that.form.bankShortNum, "that.form.bankShortNum");
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.isDisable = true;
          // if (Number(sessionStorage.getItem("bankStatusFlag")) === 1) {
          protocolFwd.param_addTrader.param.type = this.form.type;
          protocolFwd.param_addTrader.param.traderName = this.form.traderName;
          protocolFwd.param_addTrader.param.status = this.form.status;
          // 处理证件类型
          if (this.form.type == "01") {
            protocolFwd.param_addTrader.param.credentialsType = "1";
          } else {
            protocolFwd.param_addTrader.param.credentialsType = "68";
          }
          protocolFwd.param_addTrader.param.credentialsCode = this.form.credentialsCode;
          protocolFwd.param_addTrader.param.mobile = this.form.mobile;
          protocolFwd.param_addTrader.param.artificialPerson = this.form.artificialPerson;
          protocolFwd.param_addTrader.param.arcredentialsType = this.form.arcredentialsType;
          protocolFwd.param_addTrader.param.arcredentialsCode = this.form.arcredentialsCode;
          protocolFwd.param_addTrader.param.artificialContact = this.form.artificialContact;
          protocolFwd.param_addTrader.param.registCapital = this.form.registCapital;
          protocolFwd.param_addTrader.param.bankId = this.form.bankId;
          // protocolFwd.param_addTrader.param.bankShortNum = this.form.bankShortNum;
          protocolFwd.param_addTrader.param.bankAccountID = this.form.bankAccountID;
          // 处理银行类型
          console.log("银行类型", this.searchBankOptions);
          this.searchBankOptions.forEach(element => {
            if (element.value == that.form.bankShortNum) {
              protocolFwd.param_addTrader.param.bankShortNum = element.value;
            }
          });
          protocolFwd.param_addTrader.param.traderID = this.$store.state.common.sessionInfo.userId;
          if (localStorage.getItem("FirmIdList_w")) {
            var arr = JSON.parse(localStorage.getItem("FirmIdList_w"));
            if (arr.length > 0) {
              sessionStorage.setItem("marketId_w", arr[0].marketId);
            }
          }
          http.postFrontMarket(protocolFwd.param_addTrader).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              if (value.code == 0) {
                that.isDisable = false;
                // 提交按钮不可见
                this.isBtn = false;
                that.$EL_MESSAGE(message);

                // 轮询
                if (this.timerTrader) {
                  clearInterval(this.timerTrader);
                  this.timerTrader = null;
                }
                that.timerTrader = setInterval(() => {
                  that.getfindTrader();
                }, window.g.DETECTIONLOGIN_TIME);
              } else {
                that.isDisable = false;
                this.isBtn = true;
                this.$EL_MESSAGE(value.message);
              }
            } else {
              that.isDisable = false;
              that.$EL_MESSAGE(message);
            }
          });
          // } else {a
          //   this.isDisable = false;
          //   this.$EL_MESSAGE(
          //     "温馨提示：信息申报时间为交易日早9:00-15:00，晚19:30-22:00！"
          //   );
          // }
        }
      });
    },
    queryModifyTraderNameStatus() {
      http.getRes(protocolFwd.param_queryTraderNameStatus).then(response=>{
        const { code, message, value } = response.data;
        if(code === '0') {
          if(!value) {
            this.modifyTraderStatus = null
          }else{
            this.modifyForm.id = value.id
            this.modifyForm.reason = value.reason
            this.modifyTraderStatus = value.status
            this.queryTradeNameInfo = value
          }
        }
      })
    },
    modifyTraderName(type) {
      this.modifyForm.type = type
      this.modifyForm.traderName = this.form.traderName
      this.showTraderNameDialog = true
    },
    confirmModify() {
      this.$refs.modifyFormRef.validate(valid=>{
        if(valid) {
          // 首次修改
          protocolFwd.param_updateTraderName.param = {
            certType: parseInt(this.modifyForm.type),
            traderNewName: this.modifyForm.traderName,
            traderOldName: this.form.traderName,
          }
          // 重新提交
          if(this.modifyTraderStatus === 3) {
            protocolFwd.param_reBackTraderName.param = {
              id: this.modifyForm.id,
              certType: parseInt(this.modifyForm.type),
              traderNewName: this.modifyForm.traderName,
              traderOldName: this.form.traderName,
            }
          }
          this.modifyLoading = true
          http.postFront(this.modifyTraderStatus === 3 ?
              protocolFwd.param_reBackTraderName :
              protocolFwd.param_updateTraderName
          ).then(response => {
            const { code, message, value } = response.data;
            if(code === '0') {
              this.$message.success('提交 成功')
              // this.form.traderName = this.modifyForm.traderName
              this.showTraderNameDialog = false
              this.queryModifyTraderNameStatus()
            }else{
              this.$message.error(message || '提交 失败')
            }
          }).finally(()=>{
            this.modifyLoading = false
          })
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
.infoReporning {
  // background: #f2f3f8;
  // padding-top: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 18%;
}
.infoReporning .myinfoReporning {
  float: left;
  line-height: 40px;
}
.infoReporningTitle {
  overflow: hidden;
  // border: 1px solid #e6ecf2;
  width: 100%;
  padding: 15px 20px;
background: #FFFAED;
border-radius: 8px 8px 0px 0px;
}
.infoReporningTitle .myinfoReporning p {
  font-size: 16px;
}
.infoReporningTitle .myinfoReporning .tip {
  font-weight: bold;
    font-size: 16px;
    color: #E9AE2F;
    padding-left: 20px;
}
.marginLeft {
  padding-left: 90px;
}
.infoReporningContent {
 width: calc(100% - 160px);
    background-color: #ffffff;
    border-radius: 2px;
    padding: 10px 80px 20px 80px;
}
.tipsContent {
  margin-bottom: 15px;
  line-height: 30px;
  color: #9999a2;
}
.signStatus {
  margin: 10px 0;
}
.bankInfo {
  line-height: 35px;
}
.bankInfo p {
  font-size: 14px;
  color: #333333;
}
.backTable {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.chooseIcon i {
  font-size: 20px;
  cursor: pointer;
}
.chooseIcon i.fa-circle-thin {
  color: #9999a2;
}
.chooseIcon i.fa-check-circle {
  color: $commonThemeColor;
}
.submitButton {
  padding-top: 20px;
  text-align: center;
}
</style>
