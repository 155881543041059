<template>
  <div>
    <div class="notices">
      <div class="titles" v-if="currentStep == 1"  @click="goBack">
        <!-- <i
          class="el-icon-arrow-left"
          style="font-size: 18px;cursor:pointer;"
          @click="goBack"
        ></i> -->
        <!-- 新手引导 -->
        <!-- <input v-model="currentStep"/> -->
        <img src="../../assets/images/guide/fhsyj.png" alt="" srcset="" width="16px">
        <span>返回上一级</span>
      </div>
      <div class="content" v-loading="guidLoading">
       
        <div class="steps">
          <div class="step">
            <p>
              <img v-if="currentStep >= 0 "  src="../../assets/images/guide/xzsflx.png" alt="" srcset="" width="62px">
              <img v-else src="../../assets/images/guide/nxzsflx.png" alt="" srcset="" width="62px">
            </p>
            <p class="titleName" :class="currentStep >= 0 ? 'fontColor' : ''">选择身份类型</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 0" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 1" src="../../assets/images/guide/bdrz.png" alt="" srcset="" width="51px">
              <img v-else src="../../assets/images/guide/nbdrz.png" alt="" srcset="" width="51px">
            </p>
            <p class="titleName" :class="currentStep >= 1 ? 'fontColor' : ''">百度认证</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 1" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 2" src="../../assets/images/guide/sfrz.png" alt="" srcset="" width="53px">
              <img v-else src="../../assets/images/guide/nsfrz.png" alt="" srcset="" width="53px">
            </p>
            <p class="titleName" :class="currentStep >= 2 ? 'fontColor' : ''">身份认证</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 2" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 3" src="../../assets/images/guide/dt.png" alt="" srcset="" width="59px">
              <img v-else src="../../assets/images/guide/ndt.png" alt="" srcset="" width="59px">
            </p>
            <p class="titleName" :class="currentStep >= 3 ? 'fontColor' : ''">答题</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 3" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 4" src="../../assets/images/guide/ktsc.png" alt="" srcset="" width="53px">
              <img v-else src="../../assets/images/guide/nktsc.png" alt="" srcset="" width="53px">
            </p>
            <p class="titleName" :class="currentStep >= 4 ? 'fontColor' : ''">开通市场</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 4" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 5" src="../../assets/images/guide/xy.png" alt="" srcset="" width="51px">
              <img v-else src="../../assets/images/guide/nxy.png" alt="" srcset="" width="51px">
            </p>
            <p class="titleName" :class="currentStep >= 5 ? 'fontColor' : ''">协议</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 5" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 6" src="../../assets/images/guide/xxsb.png" alt="" srcset="" width="58px">
              <img v-else src="../../assets/images/guide/nxxsb.png" alt="" srcset="" width="58px">
            </p>
            <p class="titleName" :class="currentStep >= 6 ? 'fontColor' : ''">信息申报</p>
          </div>
          <div class="step">
            <p style="height: 53px;">
            </p>
            <p class="titleName">
              <img v-if="currentStep >= 6" src="../../assets/images/guide/xz.png" alt="" srcset="" width="12px">
              <img v-else src="../../assets/images/guide/wxz.png" alt="" srcset="" width="12px">
            </p>
          </div>
          <div class="step">
            <p>
              <img v-if="currentStep >= 7" src="../../assets/images/guide/qy.png" alt="" srcset="" width="52px">
              <img v-else src="../../assets/images/guide/nqy.png" alt="" srcset="" width="52px">
            </p>
            <p class="titleName" :class="currentStep >= 7 ? 'fontColor' : ''">签约</p>
          </div>
        </div>
        <!-- <el-steps
          :active="currentStep"
          finish-status="success"
          simple
          style="margin-top: 20px"
        >
          <el-step title="选择身份类型">
            <i class="step01" slot="icon"></i>
          </el-step>
          <el-step title="百度认证" style="flex-basis: 14% !important;">
          </el-step>
          <el-step
            title="身份认证"
            style="flex-basis: 14% !important;"
          ></el-step>
          <el-step title="答题" style="flex-basis: 9% !important;"></el-step>
          <el-step
            title="开通市场"
            style="flex-basis: 14% !important;"
          ></el-step>
          <el-step title="协议" style="flex-basis: 9% !important;"></el-step>
          <el-step
            title="信息申报"
            style="flex-basis: 14% !important;"
          ></el-step>
          <el-step title="签约" style="flex-basis: 9% !important;"></el-step>
        </el-steps> -->
        <div class="guideMsgBox" id="guideMsgBox">
          <!-- 类型 -->
          <!-- v-if="currentStep == 0 && !currentStepFlag" -->
          <identityGuide @popupEnter="popupEnter" v-if="currentStep == 0" />
          <!-- v-if="currentStep == 0 + 1 && currentStepFlag" -->
          <!-- 二维码 -->
          <enterpriseAuth
            v-if="currentStep == 0 + 1"
            :isXSYD="true"
            @badiuBack="baiduBack"
          />
          <!-- 认证 -->
          <systemEnterAuth
            :indetificationData="datas"
            :identityFlag_page="identityFlag_page"
            v-if="currentStep == 1 + 1"
            :BDAuthentication="BDAuthentication"
            :enterStatus="enterStatus"
            :isXSYD="true"
            @reLoad="haveEnterCer"
            @enterAuthBack="enterAuthBack"
          />
          <!-- 答题 -->
          <examinationPaper @enterInto="goPagess" v-if="currentStep == 2 + 1" />
          <!-- 开通市场 -->
          <openMarket v-if="currentStep == 3 + 1" @reloads="goProtocol" />
          <!-- 入市协议 -->
          <electronSignature @saveSignature="saveSignature" v-if="currentStep == 4 + 1 && !signatureImg && issignImg"/>
          <div v-if="currentStep == 4 + 1 && !signatureImg && !issignImg">
            <UserProtocolDY  :signatureImg="signatureImg"/>
            <div class="btns">
              <!-- <el-button @click="cancel2" style="margin-right: 70px"
            >取消</el-button
          > -->
              <el-button
                @click="enter"
                :disabled="enterDisabled"
                type="primary"
                >{{ enterName }}</el-button>
            </div>
          </div>
          <!-- 信息申报 -->
          <infoReporing v-if="currentStep == 5 + 1" @reload="goSbInfo" />
          <!-- 签约 -->
          <signBank
            v-if="currentStep == 6 + 1 || currentStep == 7 + 1"
            @reload="compeletSign"
            @signFlagReload="signFlagReload"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import enterpriseAuth from "../personalCenter/tab/baiduAuth";
import systemEnterAuth from "../personalCenter/tab/systemEnterAuth";
import examinationPaper from "./examinationPaper";
import openMarket from "./openMarket";
import infoReporing from "./infoReporing";
import UserProtocolDY from "./UserProtocolDY";
import signBank from "./signBank";
import Footer from "../../components/base/footer/footerZYQ";
import identityGuide from "./components/identityGuide";
// 上传签名
import electronSignature from './electronSignature'
import { mapGetters } from 'vuex';
export default {
  components: {
    enterpriseAuth,
    systemEnterAuth,
    examinationPaper,
    openMarket,
    infoReporing,
    signBank,
    UserProtocolDY,
    Footer,
    identityGuide,
    electronSignature
  },
  data() {
    return {
      datas: {},
      identityFlag_page: "P",
      currentStepFlag: false,
      isQyBank: "",
      isQyInfo: 0,
      approveState: "",
      currentStep: 10,
      timer1: null,
      timer2: null,
      enterDisabled: false,
      enterName: "确认",
      BDAuthentication: {
        idCardNumber: "",
        name: "",
        userId: null
      },
      guidLoading: false,
      enterStatus: 0, // 认证状态的变化
      firstPage: true, //第一次进入实名认证页 为循环检测
      intervalTime: null,
      signTime: false,
      signatureImg: '', // 签名图片
      issignImg: false // 标记状态
    };
  },
  computed: {
    ...mapGetters(['UOAStatusIntSign', 'UOAStatusGetter'])
  },
  watch: {
    enterStatus(val) {
      if (val == 2) {
        if (this.timer2) {
          clearInterval(this.timer2);
          this.timer2 = null;
        }
      }
    },
    currentStep(val) {
      this.scrollToTop();
    },
    currentStepFlag(val) {
      this.scrollToTop();
    },
    UOAStatusIntSign() {
      this.getIdentificationStatus()
    },
    UOAStatusGetter() {
      this.getData()
    }
  },
  created() {
    // 百度人脸识别查询
    // this.param_getIdentificationInfo();
  },
  mounted() {
    var that = this;
    this.getData()
    // 认证方式查询
    this.goPath();
    this.$nextTick(() => {
      document.getElementsByClassName("el-main")[0].scrollTop = 0;
    });
    // this.timer1 = setInterval(() => {
    //   that.param_getIdentificationInfo1();
    // }, 2000);
  },
  destroyed() {
    if (this.timer1) {
      clearInterval(this.timer1);
      this.timer1 = null;
    }
    if (this.timer2) {
      clearInterval(this.timer2);
      this.timer2 = null;
    }
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
      this.intervalTime = null;
    }
    if (this.signTime) {
      clearTimeout(this.signTime);
      this.signTime = null;
    }
  },
  methods: {
    // 添加签名默认值(图片)
    getData() {
      if (
        // this.UOAStatusGetter.signatureStatus &&
        this.UOAStatusGetter.signatureImg
      ) {
        this.signatureImg = JSON.parse(
          JSON.stringify(this.UOAStatusGetter.signatureImg)
        )
      }
    },
    // 添加签名成功
    saveSignature(img) {
      this.signatureImg = img
      this.currentStep == 5 + 1
    },
    signFlagReload() {
      this.currentStep = 8;
    },
    // 滚动至顶部
    scrollToTop() {
      document.getElementById("guideMsgBox").scrollTop = 0;
    },
    // 百度页面返回
    baiduBack() {
      this.currentStepFlag = false;
      this.currentStep = 0;
      if (this.timer1) {
        // alert("清除计时器");
        clearInterval(this.timer1);
        this.timer1 = null;
      }
    },
    // 认证页面返回
    enterAuthBack() {
      this.currentStepFlag = false;
      this.currentStep = 0;
      if (this.timer1) {
        // alert("清除计时器");
        clearInterval(this.timer1);
        this.timer1 = null;
      }
    },
    goPath() {
      const that = this;
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value && value.auditType) {
            if (value.auditType === "P") {
              // 个人 查询百度认证
              localStorage.setItem("identityFlag_w", "P");
              that.param_getIdentificationInfo1();
              this.identityFlag_page = "P";
              this.timer1 = setInterval(() => {
                that.param_getIdentificationInfo1();
              }, 2000);
            } else if (value.auditType === "O") {
              // 企业 查询实名认证进入下一步
              this.identityFlag_page = "O";
              this.getIdentificationStatus(); // 实名认证查询
              localStorage.setItem("identityFlag_w", "O");
              this.currentStep = 1 + 1;
            }
          } else {
            // 未选择认证类型,进入选择界面
            this.currentStep = 0;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 选择认证方式下一步
    popupEnter(flag) {
      const that = this;
      this.identityFlag_page = flag;
      if (flag == "P") {
        // 个人
        // 查询百度认证信息
        that.param_getIdentificationInfo1();
        this.timer1 = setInterval(() => {
          that.param_getIdentificationInfo1();
        }, 2000);
      } else {
        // 企业
        // 查询认证信息,进入实名认证页面
        this.getIdentificationStatus();
        this.currentStep = 1 + 1;
      }
    },
    goPagess() {
      // 答题通过查询是否开通市场开通后就跳过开通市场
      const arr1 = this.$store.state.search.firmList;
      if (
        arr1.length > 0 &&
        (arr1[0].marketId == 28 || arr1[0].marketId == 18)
      ) {
        // 已开通 this.currentStep = 3 + 1;
        // 判断是否通过协议
        this.getAgreementEnter();
      } else {
        // 未开通,打开开通市场弹窗
        this.currentStep = 3 + 1;
      }
    },

    goSbInfo() {
      // 进入签约页面
      this.getSignInfo();
      // this.currentStep = 6 + 1;
    },
    compeletSign() {
      // 进入签约页面
      this.currentStep = 7 + 1;
    },
    haveEnterCer() {
      this.firstPage = false;
      if (this.timer2) {
        clearInterval(this.timer2);
        this.timer2 = null;
      }
      // 点击了提交认证监听审核
      this.timer2 = setInterval(() => {
        this.getIdentificationStatus();
      }, 2000);
    },
    enter() {
      this.agreementEnter();
      this.currentStep = 4 + 1;
      this.issignImg = true
    },
    // 协议阅读时间
    agreementOpen() {
      var that = this;
      var time = window.g.AGREEMENT_TIME;
      this.intervalTime = setInterval(() => {
        time = Number(time) - 1000;
        if (time <= 0) {
          that.enterName = "确认";
          clearInterval(this.intervalTime);
          that.enterDisabled = false;
        } else {
          that.enterDisabled = true;
          that.enterName = `确认(${Number(time) / 1000}s)`;
        }
      }, 1000);
    },
    // 初次查询签约信息解决闪现问题
    getfirstSignInfo() {
      http
        .postFront(protocolFwd.param_queryUserSign)
        .then(response => {
          const { code, message, value } = response.data;
          // eslint-disable-next-line eqeqeq
          if (Number(code) == -180642) {
            // 未签约
            this.currentStep = 5 + 1;
            // eslint-disable-next-line eqeqeq
          } else if (Number(code) == 0) {
            if (value) {
              // eslint-disable-next-line eqeqeq
              if (value.signFlag == 1) {
                // 签约已绑卡
                this.currentStep = 7 + 1;
                // eslint-disable-next-line eqeqeq
              } else {
                this.currentStep = 6 + 1;
              }
            } else {
              this.currentStep = 5 + 1;
            }
          } else {
            this.currentStep = 5 + 1;
            this.$EL_MESSAGE(message);
          }
          this.guidLoading = false;
        })
        .catch(() => {
          this.signTime = setTimeout(() => {
            this.getfirstSignInfo();
          }, 1000);
        });
    },
    // 查询是否确认协议
    getAgreementEnter() {
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过判断是否签名
            if (this.signatureImg && this.signatureImg !== '') {
              // 协议通过,且完成签名信息申报
              this.getfindTrader();
            } else {
              // 去签名
              this.currentStep = 4 + 1;
              this.issignImg = true
            }
          } else {
            // 未通过,打开协议弹窗
            this.currentStep = 4 + 1;
            this.agreementOpen();
            this.guidLoading = false;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 确认协议接口
    agreementEnter() {
      http.postFront(protocol.param_enterAgreement).then(response => {});
    },
    goProtocol() {
      this.currentStep = 4 + 1;
      this.agreementOpen();
    },
    param_getIdentificationInfo1() {
      const _this = this;
      http.postFront(protocol.param_getIdentificationInfo).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            // 百度人脸识别通过,查询实名认证`
            this.currentStep = 1 + 1;
            this.BDAuthentication = value;
            // 实名认证查询
            this.getIdentificationStatus();
            if (_this.timer1) {
              clearInterval(_this.timer1);
              _this.timer1 = null;
            }
          } else {
            // 百度人脸识别未通过,进入二维码界面
            this.currentStep = 0 + 1;
            this.currentStepFlag = true;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 获取百度认证信息
    param_getIdentificationInfo() {
      const _this = this;
      // 处理loading
      this.guidLoading = true;
      http
        .postFront(protocol.param_getIdentificationInfo)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value) {
              this.currentStep = 1 + 1;
              this.BDAuthentication = value;
              this.getIdentificationStatus();
              if (_this.timer1) {
                clearInterval(_this.timer1);
                _this.timer1 = null;
              }
            } else {
              this.guidLoading = false;
              this.currentStep = 99;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(() => {
          this.guidLoading = false;
        });
    },
    // 查询签约信息
    getSignInfo() {
      http.postFront(protocolFwd.param_queryUserSign).then(response => {
        const { code, message, value } = response.data;
        // eslint-disable-next-line eqeqeq
        if (Number(code) == -180642) {
          // 未签约
          this.currentStep = 5 + 1;
          // eslint-disable-next-line eqeqeq
        } else if (Number(code) == 0) {
          // eslint-disable-next-line eqeqeq
          if (value) {
            // eslint-disable-next-line eqeqeq
            if (value.signFlag == 1) {
              // 签约已绑卡
              this.currentStep = 7 + 1;
              // eslint-disable-next-line eqeqeq
            } else {
              this.currentStep = 6 + 1;
            }
          } else {
            this.currentStep = 5 + 1;
          }
        } else {
          this.currentStep = 5 + 1;

          this.$EL_MESSAGE(message);
        }
      });
    },
    // 信息上报状态查询
    getfindTrader() {
      http.postFront(protocolFwd.param_findTrader).then(response => {
        const { code, message, value } = response.data;
        if (Number(code) == 0) {
          if (value && value.status == 1) {
            // 1：交易商正常，  9:交易商开户在途  8:交易商开户失败
            this.getfirstSignInfo();
            // this.currentStep = 5 + 1;
            // // this.currentStep = 7 + 1;
            // this.guidLoading = false;
            //    this.getSignInfo();
          } else {
            this.currentStep = 5 + 1;
            this.guidLoading = false;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goBack() {
      //   this.$router.go(-1);
      // this.$router.push("/index");
      // this.$router.push("/HomePage1");
      if(this.currentStep == 2) {
        this.enterAuthBack()
      }
    },
    // 查询考试是否通过
    getIsPass() {
      http.postFront(protocol.param_questinbankfrontquery).then(response => {
        var { code, value, message } = response.data;
        if (Number(code) === 0) {
          if (value) {
            if (value.length != 0 && value[0].answerIsTrue) {
              localStorage.setItem("examination_w", true);
              var arr1 = this.$store.state.search.firmList;
              // if (arr1.length > 0 && arr1[0].marketId == 28) {
              //判断是否已经开通市场
              if (arr1.length > 0) {
                // 已开通 this.currentStep = 3 + 1;
                // 判断是否通过协议
                this.getAgreementEnter();
              } else {
                // 未开通,打开开通市场弹窗
                this.currentStep = 3 + 1;
                this.guidLoading = false;
              }
            } else {
              // 未通过
              this.currentStep = 2 + 1;
              this.guidLoading = false;
              localStorage.setItem("examination_w", false);
            }
          } else {
            // 未通过
            this.currentStep = 2 + 1;
            this.guidLoading = false;
            localStorage.setItem("examination_w", false);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 认证状态查询
    getIdentificationStatus() {
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            if (value) {
              this.datas = value;
            }
            // 审核状态（0:未审核 1:已认证 2:已驳回）
            if (value && value.auditStatus == 1) {
              //   this.enterStatus = 1;
              // 已认证的状态,个人进入答题,企业跳过答题进入开通市场
              sessionStorage.setItem("auditName", value.personName);
              sessionStorage.setItem("auditType", value.auditType);
              sessionStorage.setItem("auditObj", JSON.stringify(value));
              this.$store.commit('setUOAStatus', value)
              if (this.identityFlag_page === "O") {
                // 企业
                localStorage.setItem("examination_w", true); // 填充答题通过
                // 判断是否开通市场,如已开通市场判断是否通过协议
                var arr1 = this.$store.state.search.firmList;
                //判断是否已经开通市场
                if (arr1.length > 0) {
                  // 已开通 this.currentStep = 3 + 1;
                  // 判断是否通过协议
                  this.getAgreementEnter();
                } else {
                  // 未开通,打开开通市场弹窗
                  this.currentStep = 3 + 1;
                  this.guidLoading = false;
                }
              } else {
                // 个人
                this.getIsPass();
              }
              if (this.timer2) {
                clearInterval(this.timer2);
                this.timer2 = null;
              }
            } else {
              // 未认证的状态,停留在身份认证页面
              this.enterStatus = 0;
              this.currentStep = 1 + 1;
              this.guidLoading = false;
            }
            if (value && value.auditStatus) {
              this.enterStatus = value.auditStatus;
            }
            if (value && value.auditStatus == 2 && this.firstPage) {
              this.enterStatus = 2;
              if (this.timer2) {
                clearInterval(this.timer2);
                this.timer2 = null;
              }
              // 点击了提交认证监听审核
              this.timer2 = setInterval(() => {
                this.getIdentificationStatus();
              }, 2000);
            }
          } else {
            this.$EL_MESSAGE(message);
            this.guidLoading = false;
          }
        });
    },
    leaveFor(num) {
      if (num == 1) {
        this.$store.commit("setGxLeaveForFlag", true);
        this.$router.push("/HomePage1");
      } else if (num == 2) {
        this.$router.push("/personalEnterpriseAuth");
      } else if (num == 3) {
        if (this.approveState == 1) {
          this.$router.push("/infoReporing");
        } else {
          this.$EL_MESSAGE({
            message: "请先完成第一步实名认证流程!",
            type: "warning"
          });
        }
        // this.$router.push("/infoReporing");
      } else if (num == 4) {
        this.$router.push("/userOrSafety");
      } else if (num == 5) {
        if (this.isQyInfo == 1 && (this.isQyBank == 1 || this.isQyBank == 13)) {
          this.$router.push("/StateFunding");
        } else {
          this.$EL_MESSAGE({
            message: "请先完成第三步银行开户签约流程!",
            type: "warning"
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item{
  display: flex;
  flex-direction: column;
}
/deep/ .el-form-item__label {
    text-align: left;
}
/deep/ .el-form-item__content {
    margin-left: 0 !important;
}
/deep/ .el-input__inner {
    background-color: #F3F3F3;
    border-radius: 8px;
    border: none;
}
/deep/ .el-input.is-disabled .el-input__inner{
  background-color: #F3F3F3;
}
.steps{
    display: flex;
    justify-content: space-between;
    padding: 40px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 8px;
  .step{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .titleName{
      font-family: Source Han Sans SC;
      font-weight: 500;
      font-size: 16px;
      color: #999999;
      padding-top: 30px;
    }
  }
  .fontColor{
    color: #222222 !important;
  }
}
/deep/ .wscnph {
  width: 100%;
}
.notices {
  // border: 1px solid #e6ecf2;
  width: 1250px;
  margin: 0 auto;
  // padding: 10px;
  // background-color: #fff;
  // height: 100%;
  margin-top: 20px;
  height: calc(100vh - 80px - 200px);
  overflow: hidden;
}
.newsContent {
  overflow: hidden;
}
.newsDetails .newsTitle {
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
}
.newsDetails .newsImage {
  width: 600px;
  margin: 10px auto;
}
.newsDetails .newsImage img {
  width: 100%;
}
.titles {
  cursor: pointer;
  display: flex;
    align-items: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    color: #161D43;
    span{
      padding-left: 13px;
}
    }
.btns {
  text-align: center;
}
.content {
  overflow: hidden;
  padding: 10px;
  // min-height: 600px;
  .guideItem {
    div {
      font-size: 16px;
    }
    .title {
      font-weight: 700;
      margin: 30px 0;
      font-size: 20px;
    }
    .nextBox {
      display: flex;
      align-items: center;
    }
    .btns {
      margin-top: 10px;
      text-align: center;
    }
  }
}

.nextImg {
  width: 30px;
  height: 30px;
  margin: 0 30px;
}
// /deep/.el-steps--simple {
//   padding: 13px;
// }
// /deep/.el-step.is-simple {
//   flex-basis: 15% !important;
// }
.guideMsgBox {
    // padding: 20px;
    height: calc(100vh - 82px - 200px - 172px - 101px);
    overflow: auto;
    overflow-x: hidden;
    background: #fff;
    border-radius: 8px;
}
 /deep/ .el-button--primary{
    background: linear-gradient(0deg, #4C87F0 1%, #2D69D3 100%);
    border-radius: 8px;
  }
</style>
